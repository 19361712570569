import React, {createContext, useState} from 'react';
import {Button, Card, Col, Row} from "react-bootstrap";
import ComponentIdentifier from "../CommonComponent/ComponentHeader/ComponentIdentifier";
import HeaderComponent from "../CommonComponent/ComponentHeader/HeaderComponent";
import WalletHistoryForm from "./SubComponent/WalletHistoryForm";
import WalletHistoryTable from "./SubComponent/WalletHistoryTable";

export const AgentWalletHistoryContext = createContext()
function AgentWalletHistoryComponent(props) {
    //State
    const [agentWalletHistory,setAgentWalletHistory] = useState([])

    return (
        <>
            <AgentWalletHistoryContext.Provider value={{
                agentWalletHistory,
                setAgentWalletHistory
            }}>
                <Card>
                    <Card.Header className="d-flex align-items-center justify-content-between">
                        <ComponentIdentifier link_name={"Agent Wallet History"} component_name={""}/>
                    </Card.Header>
                    <Card.Body>
                        <WalletHistoryForm/>
                        <WalletHistoryTable/>
                    </Card.Body>
                </Card>
            </AgentWalletHistoryContext.Provider>


        </>
    );
}

export default AgentWalletHistoryComponent;