import React, {useEffect, useMemo, useRef, useState} from 'react';
import {Button, Form} from "react-bootstrap";
import './PaginationStyle.css'
import Parse from "../../../Utilities/parse";
import PaginationConfiguration from "./Utilities/PaginationConfiguration";

function CommonPagination(
    {
        totalPage = 1,
        currentPage,
        setCurrentPage,
        setPageConfigurationFromCommonPagination,
        nextOnClick = (paginationConfiguration) => {
            paginationConfiguration.nextClick()
            //   paginationConfiguration.pagesDisable()
        },
        prevOnClick = (paginationConfiguration) => {
            paginationConfiguration.prevClick()
        },
        pageInputOnLeave = async (paginationConfiguration) => {
            paginationConfiguration.pageInputHandle()
        }
    }
) {
    // ref
    const nextRef = useRef()
    const prevRef = useRef()
    const pageInputRef = useRef()
    // custom method
    let paginationConfiguration = new PaginationConfiguration({
        pageSetState: setCurrentPage,
        pageState: currentPage,
        totalPage,
        pageInputRef,
        prevButtonRef: prevRef,
        nextButtonRef: nextRef
    })

    // show page no on page input lifecycle
    useEffect(() => {
        pageInputRef.current.value = currentPage
    }, [currentPage])

    // ser pagination configuration lifecycle
    useEffect(() => {
        setPageConfigurationFromCommonPagination(paginationConfiguration)
    }, [])

    return (
        <>
            <div className="d-flex flex-column align-items-center justify-content-center">
                <div className="mt-2 d-flex align-items-center">
                    {/*prev button*/}
                    <Button ref={prevRef} onClick={e => prevOnClick(paginationConfiguration)}
                            disabled={currentPage == 1 ? true : false}
                            className="btn-success btn-sm">
                        {"<"}
                    </Button>
                    {/*page number input*/}
                    <Form.Group className="mx-2 pageSwitchInput" controlId="formBasicEmail">
                        <Form.Control
                            defaultValue={currentPage}
                            ref={pageInputRef}
                            onBlur={e => pageInputOnLeave(paginationConfiguration)}
                            onKeyUp={e => {
                                e.keyCode == 13 && pageInputOnLeave(paginationConfiguration)
                            }}
                            type="number"
                            placeholder="page no."/>
                    </Form.Group>
                    {/*next button*/}
                    <Button ref={nextRef} onClick={e => {
                        nextOnClick(paginationConfiguration)
                    }}
                            disabled={currentPage == totalPage ? true : false}
                            className="btn-success btn-sm">
                        {">"}
                    </Button>
                </div>
                {/*page indicator*/}
                <span className="text-muted mt-1">
                   page {currentPage} of {totalPage}
               </span>
            </div>
        </>
    );
}

export default CommonPagination;