class MemberDueCalc {
    // due calculation
    static dueCalc(row) {
        let start_y = row.start_year
        let total_year_for_collection = 0
        let current_y = new Date().getFullYear()
        for (let i = start_y; i <= current_y; i++) {
            total_year_for_collection += 1
        }

        let totalDue = (total_year_for_collection - row.user_collection_histories?.length) * row.yearly_donation_amount
        return totalDue
    }

}

export default MemberDueCalc