import React, {useContext, useEffect, useRef, useState} from 'react';
import {Button, Col, Form, Row} from "react-bootstrap";
import {useForm} from "react-hook-form";
import Regex from "../../../Utilities/Regex";
import {MemberContext} from "../AllMemberComponent";
import {addMemberAction, memberCollectionAction} from "../../../Redux/Action/MemberAction";
import {useDispatch} from "react-redux";

function DonationForm({lable = "lable", index = "", payment = false}) {
    // ref
    const btnRef = useRef()
    const dispatch = useDispatch()
    const {
        setAllMemberReload,
        setDonationModelShow,
        getSingleMemberData,
        yearForPayment,
        setYearForPayment
    } = useContext(MemberContext)

    //Hook Form
    const {register, handleSubmit, getValues, reset, setValue, formState: {errors}} = useForm();


    //form Submit
    const onSubmit = data => {
        const payload = {
            data: {
                agent_id: getSingleMemberData.user?.id,
                member_id: getSingleMemberData.id,
                collection_year: lable,
                ...data
            },
            yearForPayment,
            setYearForPayment,
            paymentIndexForUpdate: index,
            reset,
            btnRef,
            setAllMemberReload,
            setDonationModelShow
        }
        dispatch(memberCollectionAction(payload))
    }

    return (
        <>
            <Col md={4} sm={12}>
                <form className="d-flex align-items-center" onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group className="mb-3">
                        <Form.Label>{lable}</Form.Label>
                        <Form.Control
                            autocomplete={"off"}
                            defaultValue={payment == false ? "" : payment.collection_amount}
                            disabled={payment}
                            type="text"
                            placeholder="Enter Collection Amount"
                            {...register("collection_amount", {
                                required: true,
                                pattern: Regex.numeric(),
                                min: getSingleMemberData.yearly_donation_amount,
                                max: getSingleMemberData.yearly_donation_amount
                            })}
                        />

                        {/* validation message*/}
                        {errors.collection_amount && <span className="text-danger">
                            {errors.collection_amount.type === "required" && "Please Give Amount"}
                            {errors.collection_amount.type === "pattern" && "Please Give Valid Amount"}
                            {errors.collection_amount.type === "min" && "amount can't be lower than " + getSingleMemberData.yearly_donation_amount}
                            {errors.collection_amount.type === "max" && "amount can't be higher than " + getSingleMemberData.yearly_donation_amount}
                            </span>}
                    </Form.Group>

                    {payment == false &&
                    <Button ref={btnRef} variant="primary" type="submit" className=" mt-3 ms-2 btn-sm submit">
                        Donate
                    </Button>}
                </form>

            </Col>

        </>
    );
}

export default DonationForm;