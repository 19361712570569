import React, {useContext, useRef} from 'react';
import {Button, Col, Form, Row} from "react-bootstrap";
import Regex from "../../../Utilities/Regex";
import {agentWalletHistoryAction} from "../../../Redux/Action/AgentAction";
import {useForm} from "react-hook-form";
import {useDispatch} from "react-redux";
import {AgentWalletHistoryContext} from "../AgentWalletHistoryComponent";
import ButtonLoader from "../../CommonComponent/ButtonLoader/ButtonLoader";

function WalletHistoryForm(props) {

    //ref
    const btnRef = useRef()
    // Hook
    const dispatch = useDispatch()

    // Context
    const {
        setAgentWalletHistory
    } = useContext(AgentWalletHistoryContext)


    // hook form
    const {register, handleSubmit, getValues, reset, setValue, formState: {errors}} = useForm();

    const onSubmit = data => {
        //console.log(data)
        const payload = {
            data,
            reset,
            btnRef,
            setAgentWalletHistory
        }
        dispatch(agentWalletHistoryAction(payload))


    }
    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                {/*Agent full_name field*/}
                <Row>
                    <Col md={5} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Control
                                defaultValue={20}
                                type="text"
                                placeholder="Number of Row"
                                {...register("row_count", {
                                    required: true,
                                    pattern: Regex.numeric()
                                })}
                            />

                            {/* validation message*/}
                            {errors.row_count && <span className="text-danger">
                            {errors.row_count.type === "required" && "Please Give Number of Row"}
                                {errors.row_count.type === "pattern" && "Please Give Valid Number of Row"}
                    </span>}
                        </Form.Group>
                    </Col>
                    <Col md={5} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Control
                                type="text"
                                placeholder="Enter Agent Id"
                                {...register("agent_id", {
                                    required: false,
                                    pattern: Regex.numeric()
                                })}
                            />

                            {/* validation message*/}
                            {errors.agent_id && <span className="text-danger">
                            {errors.agent_id.type === "required" && "Please Give Agent Id"}
                                {errors.agent_id.type === "pattern" && "Please Give Valid Agent Id"}
                    </span>}
                        </Form.Group>
                    </Col>
                    <Col md={2} sm={12}>
                        <Button ref={btnRef} variant="primary" type="submit" className="submit">
                            Submit
                            <ButtonLoader/>
                        </Button>
                    </Col>
                </Row>

            </form>
        </>
    );
}

export default WalletHistoryForm;