import React from 'react';
import AgentWalletHistoryComponent from "../Component/AgentWalletHistoryComponent/AgentWalletHistoryComponent";

function AgentWalletHistoryPage(props) {
    return (
        <>
            <AgentWalletHistoryComponent/>
        </>
    );
}

export default AgentWalletHistoryPage;