import React from 'react';
import { Col, Row } from 'react-bootstrap';
import AgentCollectionHistoryComponent from '../Component/AgentCollectionHistoryComponent/AgentCollectionHistoryCoponent';
import AgentWalletHistoryComponent from '../Component/AgentWalletHistoryComponent/AgentWalletHistoryComponent';

function ReportPage() {
    return (
        <>
            <Row>
                {/* wallet history */}
                <Col md={6}>
                    <AgentWalletHistoryComponent />
                </Col>
                {/* collectrion history */}
                <Col md={6}>
                    <AgentCollectionHistoryComponent />
                </Col>
            </Row>
        </>
    );
}

export default ReportPage;