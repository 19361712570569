import React, {useEffect, useState} from 'react';
import {Form} from "react-bootstrap";

function CommonSearch({preFilterData = [], setFilteredDataState, propertyForMatch = [], isTitle = true, margin = "mb-2", title = "Search", placeHolder = "placeHolder", id = "search"}) {
    const [searchKey, setSearchKey] = useState("")


    useEffect(() => {
        searchOnSubmit()
    }, [searchKey, preFilterData])

    // search on submit
    const searchOnSubmit = () => {
        let searchedData = []

        searchKey.length != 0 && propertyForMatch.length != 0 && preFilterData.length != 0 && preFilterData.filter(singleData => {
            for (let prop of propertyForMatch) {
                let stringPropToarr = prop.split(".")
                if (stringPropToarr.length == 1) {
                    if ((singleData[stringPropToarr[0]] || "").toString().toLowerCase().includes(searchKey.toLowerCase())) {
                        return singleData
                    }
                } else if (stringPropToarr.length == 2) {
                    if (singleData[stringPropToarr[0]] != null ? singleData[stringPropToarr[0]][stringPropToarr[1]].toString().toLowerCase().includes(searchKey.toLowerCase()) : true == false) {
                        return singleData
                    }
                } else if (stringPropToarr.length == 3) {
                    if (singleData[stringPropToarr[0]] != null ? singleData[stringPropToarr[0]][stringPropToarr[1]][stringPropToarr[2]].toString().toLowerCase().includes(searchKey.toLowerCase()) : true == false) {
                        return singleData
                    }
                }

            }

        }).filter(singleData => {
            searchedData.push(singleData)
        })

        setFilteredDataState(searchKey.length != 0 ? searchedData : preFilterData)

    }

    return (
        <>
            <Form onSubmit={e => {
                e.preventDefault()
                searchOnSubmit()
            }}>
                <Form.Group className={margin} controlId="formBasicEmail">
                    {isTitle == true && <Form.Label>{title}</Form.Label>}
                    <Form.Control id={id} onChange={e => {
                        let typedData = e.target.value || ""
                        setSearchKey(typedData)
                    }} type="text" placeholder={placeHolder}/>
                </Form.Group>
            </Form>
        </>
    );
}

export default CommonSearch;