import React, { useContext, useEffect, useState } from 'react';
import TableTwoBody from "../../CommonComponent/BootstrapTable2Body/TableTwoBody";
import { AgentWalletHistoryContext } from "../../AgentWalletHistoryComponent/AgentWalletHistoryComponent";
import DateFormat from "../../../Utilities/DateFormat";
import NumberSeparator from "../../../Utilities/NumberSeparator";
import { AgentCollectionHistoryContext } from "../AgentCollectionHistoryCoponent";
import { Table } from 'react-bootstrap';

function CollectionHistoryTable(props) {
    // Context
    const {
        agentCollectionHistory
    } = useContext(AgentCollectionHistoryContext)

    // states
    const [totaCollectionAmount, setTotalCollectionAmount] = useState(0)

    // making total collection amount
    useEffect(() => {
        let totalCollection = 0
        agentCollectionHistory.map(singleWallet => {
            totalCollection += singleWallet.collection_amount 
        })
        setTotalCollectionAmount(totalCollection)
    }, [agentCollectionHistory])

    const columns = [{
        dataField: 'id',
        text: 'SL',
        sort: true,
        headerStyle: (colum, colIndex) => {
            return {
                width: '100px',
            };
        },
        formatter: (cell, row, rowIndex) => {
            return <>
                {rowIndex + 1}
            </>
        }
    }, {
        dataField: 'member.member_name',
        text: 'Member Name',
        sort: true

    }, {
        dataField: 'createdAt',
        text: 'Date',
        sort: false,
        formatter: (cell, row, rowIndex) => {
            return <>
                {DateFormat.format(cell)}
            </>
        }
    }, {
        dataField: 'collection_year',
        text: 'Year',
        sort: false
    }, {
        dataField: 'collection_amount',
        text: 'Amount',
        sort: false,
        formatter: (cell, row, rowIndex) => {
            return <>
                {NumberSeparator.format(cell)}
            </>
        }
    }, {
        dataField: 'user.user_name',
        text: 'Collected By',
        sort: false
    }
    ];
    return (
        <>
            {/* total collection table */}
            <Table bordered striped>
                <tbody>
                    <tr>
                        <td>Total collection</td>
                        <td className=" text-end">{NumberSeparator.format(totaCollectionAmount)}</td>
                    </tr>
                </tbody>
            </Table>
            {/* collection history table */}
            <TableTwoBody
                products={agentCollectionHistory}
                columns={columns}
                isBorderd={true}
                is_pagination={false}
            />
        </>
    );
}

export default CollectionHistoryTable;