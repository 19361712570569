import {configureStore} from "@reduxjs/toolkit";
import {AuthSlice} from "./Slice/AuthSlice";
import {ProdCatUnitSlice} from "./Slice/ProdCatUnitSlice";
import {VendorSlice} from "./Slice/VendorSlice";
import {ChalanSlice} from "./Slice/ChalanSlice";
import {StockedItemSlice} from "./Slice/StockedItemSlice";
import {CustomerSlice} from "./Slice/CustomerSlice";
import {InvoiceSlice} from "./Slice/InvoiceSlice";
import {StaffSlice} from "./Slice/StaffSlice";
import {UserSlice} from "./Slice/UserSlice";
import {LoanSlice} from "./Slice/LoanSlice";
import {AgentSlice} from "./Slice/AgentSlice";
import {MemberSlice} from "./Slice/MemberSlice";

export const Store = configureStore({
    reducer: {
        authSlice: AuthSlice.reducer,
        prodCatUnitSlice: ProdCatUnitSlice.reducer,
        vendorSlice: VendorSlice.reducer,
        chalanSlice: ChalanSlice.reducer,
        stockedItemSlice: StockedItemSlice.reducer,
        customerSlice: CustomerSlice.reducer,
        invoiceSlice: InvoiceSlice.reducer,
        staffSlice: StaffSlice.reducer,
        userSlice: UserSlice.reducer,
        loanSlice: LoanSlice.reducer,
        agentSlice: AgentSlice.reducer,
        memberSlice: MemberSlice.reducer

    }
})