import React from 'react';
import AllMemberComponent from "../Component/AllMemberComponent/AllMemberComponent";

function AllMemberPage(props) {
    return (
        <>
            <AllMemberComponent/>
        </>
    );
}

export default AllMemberPage;
