import { AiFillSetting } from "@react-icons/all-files/ai/AiFillSetting";
import { HiUsers } from "@react-icons/all-files/hi/HiUsers";
import React from 'react';
import { FaUsersCog } from "react-icons/fa";
import { VscGraphLine } from "react-icons/vsc";
import Auth from "../../Utilities/Auth";
import SideNavUtilities from "./Utilities/SideNavUtilities";


function SideBar(props) {
    let agentDetail = JSON.parse(Auth.header().userdetail || "{}")

    return (
        <>
            <div id="layoutSidenav_nav">
                <nav className="sb-sidenav accordion sb-sidenav-dark" id="sidenavAccordion">
                    {/*side nav header*/}
                    {SideNavUtilities.header()}
                    <div className="sb-sidenav-menu">
                        <div className="nav bg-transparent">
                            {/*side nav toggle button*/}
                            {SideNavUtilities.toggleBar()}

                            {/*****************menu initialize here***************/}
                            {/*link marker*/}
                            {SideNavUtilities.marker("config", ["*"])}

                            {/*all agent link*/}
                            {SideNavUtilities.menu(
                                "Agent List",
                                "/agent",
                                ["is_admin"],
                                <FaUsersCog />
                            )}
                            {/*all member link*/}
                            {SideNavUtilities.menu(
                                "All Member",
                                "/all-member",
                                ["is_admin"],
                                <HiUsers />
                            )}
                            {/*my member link*/}
                            {JSON.parse(Auth.header().userdetail || "{}").is_admin == 0 && SideNavUtilities.menu(
                                "My Member",
                                "/my-member",
                                ["*"],
                                <HiUsers />
                            )}

                            {/********************  Report  ***********************/}
                            {SideNavUtilities.menu(
                                "Report",
                                "/report",
                                ["is_admin"],
                                <VscGraphLine />
                            )}
                            {/*drop down menue*/}
                            {/* {agentDetail.is_admin == 1 && SideNavUtilities.dropDownMenu(
                                {
                                    name: "Agent_Report",
                                    icon: <AiFillSetting/>,
                                    permissions: ["*"]
                                },
                                [
                                    {
                                        menuName: "Wallet History",
                                        link: "/agent-history",
                                        permissions: ["*"]
                                    },
                                    {
                                        menuName: "Collection History",
                                        link: "/agent-collection-history",
                                        permissions: ["*"]
                                    }
                                ])} */}
                            {/********************  Report end  ***********************/}

                            {/********************  setting  ***********************/}
                            {/*link marker*/}
                            {agentDetail.is_admin == 1 && SideNavUtilities.marker("Admin", ["*"])}
                            {/* setting */}
                            {SideNavUtilities.menu(
                                "Setting",
                                "/setting",
                                ["is_admin"],
                                <AiFillSetting />
                            )}
                            {/*drop down menue*/}
                            {/* {agentDetail.is_admin == 1 && SideNavUtilities.dropDownMenu(
                                {
                                    name: "Setting",
                                    icon: <AiFillSetting />,
                                    permissions: ["*"]
                                },
                                [
                                    {
                                        menuName: "Change password",
                                        link: "/setting",
                                        permissions: ["*"]
                                    }
                                ])} */}
                            {/********************  purchase end  ***********************/}
                        </div>
                    </div>
                    {/*sidebar footer*/}
                    {SideNavUtilities.footer()}
                </nav>
            </div>
        </>
    );
}

export default SideBar;
