import { AiOutlineDownload } from "@react-icons/all-files/ai/AiOutlineDownload";
import { GrDocumentUpload } from "@react-icons/all-files/gr/GrDocumentUpload";
import React, { createContext, useEffect, useRef, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import * as XLSX from "xlsx";
import { getAllMembersForExportAction } from "../../Redux/Action/MemberAction";
import Auth from "../../Utilities/Auth";
import ButtonLoader from "../CommonComponent/ButtonLoader/ButtonLoader";
import CommonModal from "../CommonComponent/CommonModal/CommonModal";
import ComponentIdentifier from "../CommonComponent/ComponentHeader/ComponentIdentifier";
import HeaderComponent from "../CommonComponent/ComponentHeader/HeaderComponent";
import SectionLoader from "../CommonComponent/SectionLoader/SectionLoader";
import DonationComponent from "./MemberSubComponent/DonationComponent";
import IdCar from "./MemberSubComponent/id.Car";
import ImportMemberForm from "./MemberSubComponent/ImportMemberForm";
import MemberForm from "./MemberSubComponent/MemberForm";
import MemberTable from "./MemberSubComponent/MemberTable";

export const MemberContext = createContext();

function AllMemberComponent(props) {
  // let data from auth
  let agentDetail = JSON.parse(Auth.header().userdetail || "{}");
  //Hook
  const allMemberTableLoaderRef = useRef();
  const dispatch = useDispatch();
  const membersExportRef = useRef();

  //State
  const [allMemberReload, setAllMemberReload] = useState(false);
  const [filteredMemberData, setFilteredMemberData] = useState([]);
  const [addMemberModelShow, setAddMemberModelShow] = useState(false);
  const [editMemberModelShow, setEditMemberModelShow] = useState(false);
  const [getSingleMemberData, setGetSingleMemberData] = useState({});
  const [donationModelShow, setDonationModelShow] = useState(false);
  const [yearForPayment, setYearForPayment] = useState([]);
  const [memberIdCardModelShow, setMemberIdCardModelShow] = useState(false);
  const [idData, setIdData] = useState({});
  const [searchedKey, setSearchedKey] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [importMemberModal, setImportMemberModal] = useState(false);
  const [member_barcode, setMember_barcode] = useState("");

  // data getting from store
  const allMember = useSelector((state) => state.memberSlice.allMember);

  // reset necessary state
  useEffect(() => {
    if (!addMemberModelShow && !donationModelShow && !editMemberModelShow) {
      setGetSingleMemberData({});
    }
  }, [addMemberModelShow, donationModelShow, editMemberModelShow]);

  // reset single member when update and donation modal close
  useEffect(() => {
    if (editMemberModelShow == false && donationModelShow == false) {
      setGetSingleMemberData({});
    }
  }, [editMemberModelShow, donationModelShow]);

  // export on click
  const exportOnClick = () => {
    // making payload for export members
    const payload = {
      data: { search_key: searchedKey, member_barcode },
      membersExportRef,
    };
    // action dipsatch
    dispatch(getAllMembersForExportAction(payload));
  };

  // download excel for add agent
  const downloadExcelForAddMember = () => {
    let userDetail = JSON.parse(Auth.header().userdetail || "{}");
    let wb = XLSX.utils.book_new();
    let ws = XLSX.utils.json_to_sheet([
      {
        member_name: "",
        date_of_birth: "",
        relation_with_guardian: "",
        guardian_name: "",
        mobile: "",
        start_year: "",
        yearly_donation_amount: "",
        donated: 0,
      },
    ]);
    XLSX.utils.book_append_sheet(wb, ws, "import sheet");
    XLSX.writeFile(wb, "memberImportSheet.xlsx");
  };

  return (
    <>
      <MemberContext.Provider
        value={{
          setAllMemberReload,
          setFilteredMemberData,
          setAddMemberModelShow,
          setEditMemberModelShow,
          setGetSingleMemberData,
          getSingleMemberData,
          setDonationModelShow,
          yearForPayment,
          setYearForPayment,
          memberIdCardModelShow,
          setMemberIdCardModelShow,
          idData,
          setIdData,
          setSearchedKey,
          searchedKey,
          donationModelShow,
          allMemberReload,
          currentPage,
          setCurrentPage,
          setImportMemberModal,
          member_barcode,
        }}
      >
        <Card>
          <Card.Header className="d-flex align-items-center justify-content-between">
            <ComponentIdentifier link_name={"All Member"} component_name={""} />
            <div className="d-flex align-items-center justify-content-between">
              {HeaderComponent.refresh([setAllMemberReload])}
              {HeaderComponent.add(setAddMemberModelShow)}
            </div>
          </Card.Header>
          <Card.Body>
            <div className="position-relative">
              <Row>
                <Col
                  lg={6}
                  md={6}
                  sm={12}
                  className="searchArea d-flex align-items-center mb-2"
                >
                  {/*agent search key*/}
                  <Form.Group className={""} controlId="formBasicEmail">
                    <Form.Control
                      onChange={(e) => setSearchedKey(e.target.value)}
                      id={"searchedKey"}
                      type="text"
                      placeholder={"Search Name / Village / Phone No."}
                    />
                  </Form.Group>
                  {/*agent search by id/b-code*/}
                  <Form.Group className={""} controlId="formBasicEmail">
                    <Form.Control
                      onChange={(e) => setMember_barcode(e.target.value)}
                      className="ms-2"
                      type="text"
                      style={{ width: "80px" }}
                      placeholder={"b-code"}
                    />
                  </Form.Group>
                  <Button
                    className="ms-2 btn-sm"
                    onClick={(e) => {
                      setCurrentPage(1);
                      setAllMemberReload((prev) => !prev);
                    }}
                  >
                    Search
                  </Button>
                </Col>
                {agentDetail.is_admin == 1 && (
                  <Col lg={6} md={6} sm={12} className="mt-1">
                    <Button
                      className="me-2 btn-sm"
                      variant="success"
                      onClick={(e) => downloadExcelForAddMember()}
                    >
                      <AiOutlineDownload /> Download Excel
                    </Button>
                    <Button
                      ref={membersExportRef}
                      className="me-2 btn-sm"
                      variant="info"
                      onClick={(e) => exportOnClick()}
                    >
                      <AiOutlineDownload />
                      Export Members in excel
                      <ButtonLoader />
                    </Button>
                    <Button
                      className="btn-sm"
                      variant="primary"
                      onClick={(e) => setImportMemberModal(true)}
                    >
                      <GrDocumentUpload />
                      Upload Member
                    </Button>
                  </Col>
                )}
              </Row>
              <MemberTable data={filteredMemberData} />
              <SectionLoader loading_handle_ref={allMemberTableLoaderRef} />
            </div>
          </Card.Body>
        </Card>
        {/*add member modal show*/}
        <CommonModal
          show={addMemberModelShow}
          setShow={setAddMemberModelShow}
          title={"Add Member"}
          size="lg"
        >
          <MemberForm />
        </CommonModal>

        {/*edit member modal show*/}
        <CommonModal
          show={editMemberModelShow}
          setShow={setEditMemberModelShow}
          title={"Edit Member"}
          size="lg"
        >
          <MemberForm formType={"Update"} />
        </CommonModal>

        {/*Donation collection modal show*/}
        <CommonModal
          show={donationModelShow}
          setShow={setDonationModelShow}
          title={
            "Donation Collection (" +
            getSingleMemberData.member_name +
            " DA- " +
            getSingleMemberData.yearly_donation_amount +
            ")"
          }
          size="lg"
        >
          <DonationComponent />
        </CommonModal>

        {/*Donation collection modal show*/}
        <CommonModal
          show={memberIdCardModelShow}
          setShow={setMemberIdCardModelShow}
          title={"Id Card"}
          size="lg"
        >
          <IdCar />
        </CommonModal>

        {/*import member modal*/}
        <CommonModal
          show={importMemberModal}
          setShow={setImportMemberModal}
          title={"Import member"}
          size="md"
        >
          <ImportMemberForm />
        </CommonModal>
      </MemberContext.Provider>
    </>
  );
}

export default AllMemberComponent;
