import React, { useContext, useEffect, useState } from 'react';
import { FaUserAlt } from "@react-icons/all-files/fa/FaUserAlt";
import { CgPassword } from "@react-icons/all-files/cg/CgPassword";
import { MdPayment } from "@react-icons/all-files/md/MdPayment";
import { FiEdit } from "@react-icons/all-files/fi/FiEdit";
import TableTwoBody from "../../CommonComponent/BootstrapTable2Body/TableTwoBody";
import paginationFactory from "react-bootstrap-table2-paginator";
import { AgentWalletHistoryContext } from "../AgentWalletHistoryComponent";
import DateFormat from "../../../Utilities/DateFormat";
import NumberSeparator from "../../../Utilities/NumberSeparator";
import { Table } from 'react-bootstrap';

function WalletHistoryTable(props) {
    // Context
    const {
        agentWalletHistory
    } = useContext(AgentWalletHistoryContext)
    // states
    const [totalWalletAmount, setTotalWalletAmount] = useState(0)

    // making total wallet amount
    useEffect(() => {
        // console.log(agentWalletHistory)
        let totalWallet = 0
        agentWalletHistory.map(singleWallet => {
            totalWallet += singleWallet.balance_added_amount 
        })
        setTotalWalletAmount(totalWallet)
    }, [agentWalletHistory])

    const columns = [{
        dataField: 'id',
        text: 'SL',
        sort: true,
        headerStyle: (colum, colIndex) => {
            return {
                width: '100px',
            };
        },
        formatter: (cell, row, rowIndex) => {
            return <>
                {rowIndex + 1}
            </>
        }
    }, {
        dataField: 'agent_id',
        text: 'Agent name',
        sort: true,
        formatter: (cell, row, rowIndex) => {
            return <>
                {row?.user?.user_name + " (" + cell + ")"}
            </>
        }

    }, {
        dataField: 'createdAt',
        text: 'Date',
        sort: false,
        formatter: (cell, row, rowIndex) => {
            return <>
                {DateFormat.format(cell)}
            </>
        }
    }, {
        dataField: 'balance_added_amount',
        text: 'Amount',
        sort: false,
        formatter: (cell, row, rowIndex) => {
            return <>
                {NumberSeparator.format(cell)}
            </>
        }
    }

    ];

    return (
        <>
            {/* total wallet table */}
            <Table bordered striped>
                <tbody>
                    <tr>
                        <td>Total wallet</td>
                        <td className=" text-end">{NumberSeparator.format(totalWalletAmount)}</td>
                    </tr>
                </tbody>
            </Table>
            {/* wallet history table */}
            <TableTwoBody
                products={agentWalletHistory}
                columns={columns}
                isBorderd={true}
                is_pagination={false}
            />
        </>
    );
}

export default WalletHistoryTable;