import React, {useContext, useEffect, useRef, useState} from 'react';
import {useForm} from "react-hook-form";
import {Button, Col, Form, Row} from "react-bootstrap";
import Regex from "../../../Utilities/Regex";
import {useDispatch} from "react-redux";
import {AgentContext} from "../AgentComponent";
import {addAgentAction, updateAgentAction} from "../../../Redux/Action/AgentAction";
import Auth from "../../../Utilities/Auth";


function AgentForm({formType = "add"}) {
    // ref
    const btnRef = useRef()

    // Hook
    const dispatch = useDispatch()

    //Context
    const {
        setAgentAddModalShow,
        setAllAgentReload,
        getSingleAgentData,
        setAgentEditModalShow
    } = useContext(AgentContext)

    // hook form
    const {register, handleSubmit, getValues, reset, setValue, formState: {errors}} = useForm();
    // states
    const [newAvatar, setNewAvatar] = useState(null)

    // default data set when update
    useEffect(() => {
        if (Object.keys(getSingleAgentData).length != 0) {
            Object.keys(getValues()).map(fieldProp => {
                setValue(fieldProp, getSingleAgentData[fieldProp])
            })
        }


    }, [getSingleAgentData])

    const onSubmit = async data => {
        let formData = new FormData()
        formData.append("new_avatar", newAvatar)
        formType!="add" && formData.append("agent_id", getSingleAgentData.id)
        formType!="add" && formData.append("avatar", getSingleAgentData.avatar)
        Object.keys(data).map(fieldName => {
            formData.append(fieldName, data[fieldName])
        })
        const payload = {
            data: formData,
            reset,
            btnRef,
            setAllAgentReload
        }
        if (formType == "add") {
            payload.setAgentAddModalShow = setAgentAddModalShow
            dispatch(addAgentAction(payload))

        } else {
            payload.setAgentEditModalShow = setAgentEditModalShow
           dispatch(updateAgentAction(payload))


        }

    }
    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                {/*Agent full_name field*/}
                <Row>
                    <Col md={6} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Agent Image</Form.Label>
                            <Form.Control
                                type="file"
                                placeholder="Enter Agent Name"
                                onChange={e => {
                                    setNewAvatar(e.target?.files[0])
                                }}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Agent Full Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter Agent Name"
                                {...register("full_name", {
                                    required: true
                                })}
                            />

                            {/* validation message*/}
                            {errors.full_name && <span className="text-danger">
                        {errors.full_name.type === "required" && "Please Give Agent Name"}
                    </span>}
                        </Form.Group>
                    </Col>
                    <Col md={6} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Agent Mobile No.</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter Mobile No"
                                {...register("mobile", {
                                    required: true,
                                    pattern: Regex.numeric()
                                })}
                            />

                            {/* validation message*/}
                            {errors.mobile && <span className="text-danger">
                            {errors.mobile.type === "required" && "Please Give Agent Mobile No."}
                                {errors.mobile.type === "pattern" && "Please Give Valid Mobile No."}
                    </span>}
                        </Form.Group>
                    </Col>
                    <Col md={6} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter Agent Email"
                                {...register("email", {
                                    required: true,
                                    // pattern:Regex.email()
                                })}
                            />

                            {/* validation message*/}
                            {errors.email && <span className="text-danger">
                            {errors.email.type === "required" && "Please Give Email Id"}
                                {/*{errors.email.type === "pattern" && "Please Give Valid Email Id"}*/}
                    </span>}
                        </Form.Group>
                    </Col>
                    {/*<Col md={6} sm={12}>*/}
                    {/*    <Form.Group className="mb-3" controlId="formBasicEmail">*/}
                    {/*        <Form.Label>Agent Address</Form.Label>*/}
                    {/*        <Form.Control*/}
                    {/*            type="text"*/}
                    {/*            placeholder="Enter Agent Address"*/}
                    {/*            {...register("agent_address", {*/}
                    {/*                required: true*/}
                    {/*            })}*/}
                    {/*        />*/}

                    {/*        /!* validation message*!/*/}
                    {/*        {errors.agent_address && <span className="text-danger">*/}
                    {/*        {errors.agent_address.type === "required" && "Please Give Agent Address"}*/}
                    {/*</span>}*/}
                    {/*    </Form.Group>*/}
                    {/*</Col>*/}

                    <Col md={6} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>District</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter District Name"
                                {...register("collection_area_district", {
                                    required: true
                                })}
                            />

                            {/* validation message*/}
                            {errors.collection_area_district && <span className="text-danger">
                            {errors.collection_area_district.type === "required" && "Please Give District"}
                    </span>}
                        </Form.Group>
                    </Col>
                    <Col md={6} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Police Station</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter Police Station"
                                {...register("collection_area_police_station", {
                                    required: true
                                })}
                            />

                            {/* validation message*/}
                            {errors.collection_area_police_station && <span className="text-danger">
                            {errors.collection_area_police_station.type === "required" && "Please Give Police Station"}
                    </span>}
                        </Form.Group>
                    </Col>

                    <Col md={6} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Post Office</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter Post Office"
                                {...register("collection_area_post_office", {
                                    required: true
                                })}
                            />

                            {/* validation message*/}
                            {errors.collection_area_post_office && <span className="text-danger">
                            {errors.collection_area_post_office.type === "required" && "Please Give Post Office"}
                    </span>}
                        </Form.Group>
                    </Col>
                    <Col md={6} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Village</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter Village Name"
                                {...register("collection_area_village", {
                                    required: true
                                })}
                            />

                            {/* validation message*/}
                            {errors.collection_area_village && <span className="text-danger">
                            {errors.collection_area_village.type === "required" && "Please Give Village Name"}
                    </span>}
                        </Form.Group>
                    </Col>
                    <Col md={6} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>User Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter Agent Mobile No."
                                {...register("user_name", {
                                    required: true,
                                    // pattern:Regex.numeric()
                                })}
                            />

                            {/* validation message*/}
                            {errors.user_name && <span className="text-danger">
                            {errors.user_name.type === "required" && "Please Give Agent phone No."}
                                {/*{errors.user_name.type === "pattern" && "Please Give Agent phone No."}*/}
                    </span>}
                        </Form.Group>
                    </Col>
                    {formType == "add" && <>
                        <Col md={6} sm={12}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Password</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter Password"
                                    {...register("password", {
                                        required: true
                                    })}
                                />

                                {/* validation message*/}
                                {errors.password && <span className="text-danger">
                            {errors.password.type === "required" && "Please Give Password"}
                    </span>}
                            </Form.Group>
                        </Col>
                        <Col md={6} sm={12}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Balance</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter Amount"
                                    {...register("balance", {
                                        required: true,
                                        pattern: Regex.numeric()
                                    })}
                                />

                                {/* validation message*/}
                                {errors.balance && <span className="text-danger">
                            {errors.balance.type === "required" && "Please Give Amount"}
                                    {errors.balance.type === "pattern" && "Please Valid Amount"}
                    </span>}
                            </Form.Group>
                        </Col>
                    </>}
                    <Col md={12} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Remark</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter Remark"
                                {...register("remark", {
                                    required: false

                                })}
                            />


                        </Form.Group>
                    </Col>
                </Row>

                <Button ref={btnRef} variant="primary" type="submit" className="submit">
                    {formType === "add" ? "Submit" : "Update"}
                </Button>

            </form>
        </>
    );
}

export default AgentForm;