import {Form} from "react-bootstrap";
import DatePicker from "react-datepicker";
import {FcOvertime} from "@react-icons/all-files/fc/FcOvertime";
import React from "react";

class CommonDatePicker {
    constructor() {
    }

    static dateField(dateState, setDateState, title = "From date", date_formate = "dd/MM/yyyy", showMonthYearPicker = false) {
        return <Form.Group className="mb-2">
            <Form.Label>{title}</Form.Label>
            <br/>
            <div className="datePickerDiv">
                <DatePicker
                    className="text-muted form-control"
                    selected={dateState}
                    dateFormat={date_formate}
                    onChange={(date) => setDateState(date)}
                    showMonthDropdown
                    showYearDropdown
                    showMonthYearPicker={showMonthYearPicker}
                />
                <span className="text-success dateTimeIcon "><FcOvertime/></span>
            </div>
        </Form.Group>
    }
}

export default CommonDatePicker