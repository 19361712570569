class Regex {
    constructor(props) {
    }

    // numeric regex
    static numeric() {
        return /^-?\d*\.?\d*$/
    }
    // Email regex
    static email() {
        return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    }
}

// export
export default Regex