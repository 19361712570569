import React, {useEffect, useRef, useState} from 'react';
import {Button, Col, Form, FormControl, Row} from 'react-bootstrap'
import {useForm} from "react-hook-form";
import ButtonLoader from "../../CommonComponent/ButtonLoader/ButtonLoader";
import {Navigate, useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {loginAction} from "../../../Redux/Action/UserAction";

function LoginForm(props) {
    // hooks
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {register, handleSubmit, watch, formState: {errors}} = useForm();

    //ref
    const loginBtnRef = useRef()
    // state
    const [isLogged, setIsLogged] = useState(false)

    // redirect to dashboard after login lifecycle
    useEffect(() => {
        isLogged == true && navigate("/dashboard")
    }, [isLogged])

    // form
    const onSubmit = async data => {
        // making payload
        let payload = {
            data: {...data},
            setIsLogged,
            loginBtnRef
        }
        //action dispatch
        dispatch(loginAction(payload))

    }

    return (
        <>
            <Row className="w-100 justify-content-center">
                <Col className="loginForm" md={10}>
                    <Form onSubmit={handleSubmit(onSubmit)} className="text-start mt-4">
                        {/*user name field*/}
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>User name</Form.Label>
                            <Form.Control
                                className={errors.user_name ? "is-invalid" : " "}
                                type="text"
                                placeholder="Enter user name"
                                {...register("user_name", {required: true})}
                            />

                            {/*validation message*/}
                            {errors.user_name && <span className="text-danger">This field is required</span>}

                        </Form.Group>

                        {/*password filed*/}
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                className={errors.password ? "is-invalid" : ""}
                                type="password"
                                placeholder="Password"
                                {...register("password", {required: true})}
                            />

                            {/*validation message*/}
                            {errors.password && <span className="text-danger">This field is required</span>}
                        </Form.Group>

                        {/*button*/}
                        <Button className="submitBtn" ref={loginBtnRef} variant="success" type="submit">
                            Login
                            <ButtonLoader/>
                        </Button>
                    </Form>
                </Col>
            </Row>
        </>
    );
}

export default LoginForm;