import React from 'react';
import AgentComponent from "../Component/AgentComponent/AgentComponent";

function AgentPage(props) {
    return (
        <>
            <AgentComponent/>
        </>
    );
}

export default AgentPage;