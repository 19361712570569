import React, {useEffect} from 'react';
import {Button, Container} from "react-bootstrap";
import {Store} from "../Redux/Store";
import construction from "../assets/images/construction.webp";
import {ToastContainer} from "react-toastify";
import CommonToast from "../Utilities/CommonToast";
import Auth from "../Utilities/Auth";
import {useLocation, useNavigate} from "react-router-dom";

function DashboardPage(props) {
    let navigate = useNavigate()
    let location = useLocation()
    useEffect(() => {
        {
            JSON.parse(Auth.header().userdetail || "{}").is_admin == 1 ? navigate("/agent") : navigate("/my-member")
        }
    }, [location])


    return (
        <>
            Loading...
        </>
    );
}

export default DashboardPage;