import Parse from "../../../../Utilities/parse";

class PaginationConfiguration {
    constructor({pageSetState, pageState, totalPage, pageInputRef, prevButtonRef, nextButtonRef}) {
        this._pageSetState = pageSetState
        this._pageState = pageState
        this._totalPage = totalPage
        this._pageInputRef = pageInputRef
        this._prevButtonRef = prevButtonRef
        this._nextButtonRef = nextButtonRef
    }

    // page disable enable body
    _disableEnableBody(pageDisabilityStatus = true) {
        this._pageInputRef.current.disabled = pageDisabilityStatus
        this._prevButtonRef.current.disabled = pageDisabilityStatus
        this._nextButtonRef.current.disabled = pageDisabilityStatus
    }

    // pages disable
    pagesDisable() {
        this._disableEnableBody(true)
    }

    // pages enable
    pagesEnable() {
        this._disableEnableBody(false)
    }


    // next click
    nextClick() {
        this._pageSetState(prev => prev + 1)
    }

    // previous click
    prevClick() {
        this._pageSetState(prev => prev - 1)
    }

    // page input handle
    pageInputHandle() {
        let typedNumber = this._pageInputRef.current.value
        typedNumber = typedNumber > this._totalPage ? this._totalPage : typedNumber > 0 ? typedNumber : 1
        this._pageSetState(Parse.toNumber(typedNumber))
    }

}

export default PaginationConfiguration