import { FiEdit } from "@react-icons/all-files/fi/FiEdit";
import { MdPayment } from "@react-icons/all-files/md/MdPayment";
import React from "react";
import { FaRegIdCard } from "react-icons/fa";
import NumberSeparator from "../../../Utilities/NumberSeparator";
import MemberDueCalc from "./MemberDueCalc";

class MemberTableConfig {
    constructor() {
    }

    static allColumn({ setMemberIdCardModelShow, setIdData, currentPage, setGetSingleMemberData, setEditMemberModelShow, setDonationModelShow }) {
        return [{
            dataField: 'id',
            text: 'Sl',
            sort: true,
            headerStyle: (colum, colIndex) => {
                return {
                    width: '60px',
                };
            },
            formatter: (cell, row, rowIndex) => {
                return ((currentPage - 1) * 50) + rowIndex + 1
            }

        }, {
            dataField: 'id',
            text: 'B-code',
            sort: true,
            headerStyle: (colum, colIndex) => {
                return {
                    width: '80px',
                };
            },

        }, {
            dataField: 'member_name',
            text: 'Name',
            sort: true,
            headerStyle: (colum, colIndex) => {
                return {
                    width: '200px',
                };
            }
        }, {
            dataField: 'guardian_name',
            text: 'Guardian Name',
            sort: true,
            headerStyle: (colum, colIndex) => {
                return {
                    width: '200px',
                };
            }
        }, {
            dataField: 'mobile',
            text: 'Phone No.',
            sort: false,
            headerStyle: (colum, colIndex) => {
                return {
                    width: '150px',
                };
            }
        }, {
            dataField: 'id',
            text: 'Agent id',
            sort: true,
            headerStyle: (colum, colIndex) => {
                return {
                    width: '90px',
                };
            },
            formatter: (cell, row, rowIndex) => {
                return <>
                    {row.user?.id}
                </>
            }
        }, {
            dataField: 'user.collection_area_village',
            text: 'Village',
            sort: true,
            headerStyle: (colum, colIndex) => {
                return {
                    width: '150px',
                };
            }
        }, {
            dataField: 'yearly_donation_amount',
            text: 'Yearly Amount',
            sort: true,
            headerStyle: () => {
                return {
                    width: '160px'
                }
            }
        }, {
            dataField: 'user.collection_area_post_office',
            text: 'P.O',
            sort: true,
            headerStyle: () => {
                return {
                    width: '120px'
                }
            }
        }, {
            dataField: 'user.collection_area_police_station',
            text: 'P.S',
            sort: true,
            headerStyle: () => {
                return {
                    width: '120px'
                }
            }
        }, {
            dataField: 'start_year',
            text: 'Start Year',
            sort: true,
            headerStyle: () => {
                return {
                    width: '120px'
                }
            }
        }, {
            dataField: 'balance',
            text: 'Total Paid',
            sort: true,
            headerStyle: () => {
                return {
                    width: '100px'
                }
            },
            formatter: (cell, row) => {

                let totalCollection = 0
                row.user_collection_histories?.map(singleCollection => {
                    totalCollection += singleCollection.collection_amount
                })
                return <>
                    {row.member_name != "Loading..." ? NumberSeparator.format(totalCollection) : "Loading..."}
                </>
            }
        }, {
            dataField: 'balance',
            text: 'Due Payment',
            sort: true,
            headerStyle: () => {
                return {
                    width: '130px'
                }
            },
            formatter: (cell, row) => {

                return <>
                    {row.member_name != "Loading..." ? NumberSeparator.format(MemberDueCalc.dueCalc(row)) : "Loading..."}
                </>
            }
        }, {
            dataField: 'id',
            text: 'Action',
            headerStyle: () => {
                return {
                    width: '120px',
                }
            },
            style: { textAlign: "center" },
            formatter: ((cell, row) => {
                return row.member_name != "Loading..." ? <>
                    {/*edit member*/}
                    <button title="EDIT"
                        className="bg-transparent iconActionButton border-0 text-success btn btn-primary"
                        onClick={e => {
                            //single data for update
                            setGetSingleMemberData(row)
                            // update model open
                            setEditMemberModelShow(true)

                        }
                        }>
                        <FiEdit />
                    </button>
                    {/*Add donation*/}
                    <button title="PAYMENT"
                        className="bg-transparent iconActionButton border-0 text-success btn btn-primary"
                        onClick={e => {
                            //single data for update
                            setGetSingleMemberData(row)

                            // Donation model open
                            setDonationModelShow(true)
                        }
                        }>
                        <MdPayment />
                    </button>
                    {/*id card*/}
                    <button title="ID CARD"
                        className="bg-transparent iconActionButton border-0 text-success btn btn-primary"
                        onClick={e => {
                            //single data for id card
                            setIdData(row)

                            // card model open
                            setMemberIdCardModelShow(true)
                        }
                        }>
                        <FaRegIdCard />
                    </button>
                </> : "Loading..."
            })

        }]
    }
}

export default MemberTableConfig