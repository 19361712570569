import React, {useContext, useRef} from 'react';
import {useForm} from "react-hook-form";
import {Button, Form} from "react-bootstrap";
import Regex from "../../../Utilities/Regex";
import {AgentContext} from "../AgentComponent";
import {addWalletAction, updateAgentAction} from "../../../Redux/Action/AgentAction";
import {useDispatch} from "react-redux";
import CommonToast from "../../../Utilities/CommonToast";
import ButtonLoader from "../../CommonComponent/ButtonLoader/ButtonLoader";

function AddWalletForm(props) {

    // Ref
    const addWalletRef = useRef()
    // Hook
    const dispatch = useDispatch()

    const {
        setGetSingleAgentData,
        setAddWalletModelShow,
        getSingleAgentData,
        setAllAgentReload,
        setChangePasswordModelShow
    } = useContext(AgentContext)

    // hook form
    const {register, handleSubmit, getValues, reset, setValue, formState: {errors}} = useForm();

    const onSubmit = data => {
        const payload = {
            data: {...data, user_id: getSingleAgentData.id},
            reset,
            addWalletRef,
            setAllAgentReload,
            setAddWalletModelShow,
            setChangePasswordModelShow
        }

        dispatch(addWalletAction(payload))
    }

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Wallet</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Enter Wallet"
                        {...register("balance", {
                            required: true,
                            pattern: Regex.numeric()
                        })}
                    />

                    {/* validation message*/}
                    {errors.balance && <span className="text-danger">
                        {errors.balance.type === "required" && "Please Give Wallet Amount"}
                        {errors.balance.type === "pattern" && "Please Give Valid Amount"}
                    </span>}
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Remark</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Enter Remark"
                        {...register("remark", {
                            required: false
                        })}
                    />
                </Form.Group>
                <Button ref={addWalletRef} variant="primary" type="submit" className="submit">
                    Submit
                    <ButtonLoader/>
                </Button>
            </form>
        </>
    );
}

export default AddWalletForm;