import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-datepicker/dist/react-datepicker.css";
import { HashRouter } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import AppRoute from "./AllRoutes/AppRoute";
import "./assets/css/custom.css";

function App(props) {
  return (
    <>
      <HashRouter>
        <AppRoute />
      </HashRouter>
    </>
  );
}

export default App;
