import React, {useEffect} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import Auth from "../Utilities/Auth";
import AllMemberComponent from "../Component/AllMemberComponent/AllMemberComponent";

function MyMemberPage(props) {
    let navigate = useNavigate()
    let location = useLocation()
    useEffect(() => {
        {
            JSON.parse(Auth.header().userdetail || "{}").is_admin != 0 && navigate("/agent")
        }
    }, [location])
    if (JSON.parse(Auth.header().userdetail || "{}").is_admin == 0) {
        return (
            <>
                <AllMemberComponent/>
            </>
        );
    } else {
        return (
            <>
                <h5>Loading...</h5>
            </>
        );
    }

}

export default MyMemberPage;