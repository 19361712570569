import React, {useContext, useRef, useState} from 'react';
import {Form, Button} from "react-bootstrap";
import {useForm} from "react-hook-form";
import Auth from "../../../Utilities/Auth";
import * as XLSX from "xlsx";
import {importAgentAction} from "../../../Redux/Action/AgentAction";
import {useDispatch} from "react-redux";
import ButtonLoader from "../../CommonComponent/ButtonLoader/ButtonLoader";
import {MemberContext} from "../AllMemberComponent";
import {importMemberAction} from "../../../Redux/Action/MemberAction";

function ImportMemberForm(props) {
    // hooks
    const dispatch = useDispatch()
    const submitRef = useRef()
    // hook form props
    const {register, handleSubmit, watch, formState: {errors}} = useForm();
    // states
    const [excelFile, setExcelFile] = useState(null);
    const [excelFileError, setExcelFileError] = useState(null);
    // getting data form context
    const {setImportMemberModal, setAllMemberReload} = useContext(MemberContext)

    // handle File
    const fileType = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
    const handleFile = (e) => {
        let selectedFile = e.target.files[0];
        if (selectedFile) {
            // console.log(selectedFile.type);
            if (selectedFile && fileType.includes(selectedFile.type)) {
                let reader = new FileReader();
                reader.readAsArrayBuffer(selectedFile);
                reader.onload = (e) => {
                    setExcelFileError(null);
                    setExcelFile(e.target.result);
                }
            } else {
                setExcelFileError('Please select only excel file types');
                setExcelFile(null);
            }
        } else {
            console.log('plz select your file');
        }
    }

    // on submit
    const onSubmit = hookFormData => {
        let userDetail = JSON.parse(Auth.header().userdetail || "{}")
        if (excelFile !== null) {
            const workbook = XLSX.read(excelFile, {type: 'buffer'});
            const worksheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[worksheetName];
            const new_member_arr = XLSX.utils.sheet_to_json(worksheet);

            // making payload for import members
            const payload = {
                data: {
                    agent_id: hookFormData.agent_id,
                    new_member_arr
                },
                setImportMemberModal,
                setAllMemberReload,
                submitRef
            }
            // action dispatch
            dispatch(importMemberAction(payload))
        } else {

        }
    }
    return (
        <>
            <Form onSubmit={handleSubmit(onSubmit)}>
                {/*Agent id*/}
                <Form.Group className="mb-2" controlId="formBasicEmail">
                    <Form.Label>Agent id</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Enter agent id"
                        {...register("agent_id", {required: true})}
                    />
                    {/*validation message*/}
                    {errors.agent_id && <span className="text-danger">This field is required.</span>}
                </Form.Group>
                <Form.Group className="mb-2" controlId="formBasicEmail">
                    <Form.Label>Upload members</Form.Label>
                    <Form.Control
                        type="file"
                        // onChange={e => handleFile(e)}
                        {...register("importedMemberExcel", {
                            required: true,
                            onChange: e => {
                                handleFile(e)
                            }
                        })}
                    />
                    {errors.importedMemberExcel && <span className="text-danger">This field is required.</span>}
                    {excelFileError && <div className='text-danger'
                                            style={{marginTop: 5 + 'px'}}>{excelFileError}</div>}
                </Form.Group>

                <Button ref={submitRef} variant="success" disabled={false} className="mt-2 btn-sm" type="submit">
                    Add
                    <ButtonLoader/>
                </Button>
            </Form>
        </>
    );
}

export default ImportMemberForm;