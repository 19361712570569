import React, {useContext, useEffect, useRef, useState} from 'react';
import {useDispatch} from "react-redux";
import {MemberContext} from "../AllMemberComponent";
import {useForm} from "react-hook-form";
import {memberCollectionAction} from "../../../Redux/Action/MemberAction";
import {Button, Col, Form, Row} from "react-bootstrap";
import Regex from "../../../Utilities/Regex";
import DonationForm from "./DonationForm";

function DonationComponent(props) {
    // ref
    const btnRef = useRef()
    const dispatch = useDispatch()
    const {
        setAllMemberReload,
        setDonationModelShow,
        getSingleMemberData,
        yearForPayment,
        setYearForPayment
    } = useContext(MemberContext)


    // making active years lifecycle
    useEffect(() => {
        if (Object.keys(getSingleMemberData).length != 0) {
            let start_y = getSingleMemberData.start_year
            let current_y = new Date().getFullYear()

            // formatting year for payment
            let year_for_payments = []
            for (let i = start_y; i <= current_y; i++) {
                let payment = {
                    payment_year: i,
                    payment: false
                }
                // getting payed year
                for (let collection of getSingleMemberData.user_collection_histories) {
                    if (collection.collection_year == i) {
                        payment.payment = collection
                        break
                    }
                }
                // year for payment push
                year_for_payments.push(payment)
            }
            // set year for payments state
            setYearForPayment(year_for_payments)
        } else {
            setYearForPayment([])
        }

    }, [getSingleMemberData])


    return (
        <>
            <Row>
                {yearForPayment.map((year, index) => (
                    <DonationForm lable={year.payment_year} payment={year.payment} index={index}/>
                ))}
            </Row>

        </>
    );
}

export default DonationComponent;