class NumberSeparator {
    constructor() {
    }

    static format(num = 0, suffix = "/-", fraction_number = 2) {
        let afterDecimal = Number(num).toFixed(fraction_number)
        return Number(afterDecimal).toLocaleString("en-in") + suffix
    }
}

export default NumberSeparator