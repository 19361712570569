import axios from "axios";
import Auth from "./Auth";
import CommonToast from "./CommonToast";

class CommonAxios {
    constructor() {
    }

    // post axios body initialize
    axiosBody(url, payload, reqType = "post", isToast = true, is_form_data = false) {
        // return promiss
        return new Promise((resolve, reject) => {
            let headers = {
                token: Auth.header().token
            }
            if (is_form_data == true) {
                headers = {
                    'Content-Type': 'multipart/form-data',
                    ...headers
                }
            }
            // db operation
            axios[reqType](url, reqType == "post" ? payload.data : {headers}, {headers}).then(async res => {
                // if user's role or permission update then, we need to update session
                res.data.token != undefined && await Auth.storage(res.data)
                // send response
                resolve(res)
            }).catch(err => {
                if (err.response?.status == 401) { // if 401 un-authorize
                    Auth.clear()
                } else { // other all error
                    isToast == true && CommonToast.error("something went wrong! please try again.")
                }
                // send err
                reject(err)
            })
        })
    }

    // post request
    static post(url, payload) {
        return new CommonAxios().axiosBody(url, payload, "post", true)
    }

    // form post
    static formDataPost(url, payload) {
        return new CommonAxios().axiosBody(url, payload, "post", true, true)
    }

    // silent post request. it has no default error toast
    static silentPost(url, payload) {
        return new CommonAxios().axiosBody(url, payload, "post", false)
    }


    // get request
    static get(url) {
        return new CommonAxios().axiosBody(url, "", "get", false)
    }
}

export default CommonAxios