import {createSlice} from "@reduxjs/toolkit";

export const MemberSlice = createSlice({
    name: "MemberSlice",
    initialState: {
        allMember: [],
        totalAmountForCollection: 0,
        users_total_collection: 0
    },
    reducers: {
        allMemberReducer: (state, action) => {
            state.allMember = action.payload
        },
        getTotalDueReducer: (state, action) => {
            state.totalAmountForCollection = action.payload.totalAmountForCollection
            state.users_total_collection = action.payload.users_total_collection
        }
    }


})
