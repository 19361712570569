import { AiOutlineDownload } from "@react-icons/all-files/ai/AiOutlineDownload";
import { GrDocumentUpload } from "@react-icons/all-files/gr/GrDocumentUpload";
import React, { createContext, useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import * as XLSX from 'xlsx';
import { getAllAgentAction } from "../../Redux/Action/AgentAction";
import Auth from "../../Utilities/Auth";
import CommonModal from "../CommonComponent/CommonModal/CommonModal";
import CommonSearch from "../CommonComponent/CommonSearch/CommonSearch";
import ComponentIdentifier from "../CommonComponent/ComponentHeader/ComponentIdentifier";
import HeaderComponent from "../CommonComponent/ComponentHeader/HeaderComponent";
import SectionLoader from "../CommonComponent/SectionLoader/SectionLoader";
import AddWalletForm from "./AgentSubComponent/AddWalletForm";
import AgentForm from "./AgentSubComponent/AgentForm";
import AgentImport from "./AgentSubComponent/AgentImport";
import AgentTable from "./AgentSubComponent/AgentTable";
import ChangePasswordForm from "./AgentSubComponent/ChangePasswordForm";

export const AgentContext = createContext()

function AgentComponent(props) {
    // Hook
    const dispatch = useDispatch()
    const allAgentTableLoaderRef = useRef()

    //State
    const [allAgentReload, setAllAgentReload] = useState(false)
    const [agentAddModalShow, setAgentAddModalShow] = useState(false)
    const [agentEditModalShow, setAgentEditModalShow] = useState(false)
    const [addWalletModelShow, setAddWalletModelShow] = useState(false)
    const [changePasswordModelShow, setChangePasswordModelShow] = useState(false)
    const [importModalShow, setImportModalShow] = useState(false)

    const [filteredAgentData, setFilteredAgentData] = useState([])
    const [getSingleAgentData, setGetSingleAgentData] = useState({})

    // data getting from store
    const allAgent = useSelector(state => state.agentSlice.allAgent)

    // single agent data reset
    useEffect(() => {
        if (agentEditModalShow == false && changePasswordModelShow == false && addWalletModelShow == false) {
            setGetSingleAgentData({})
        }
    }, [agentEditModalShow, changePasswordModelShow, addWalletModelShow])

    // get all  agent lifecycle
    useEffect(() => {
        const Payload = {
            allAgentTableLoaderRef,
        }
        dispatch(getAllAgentAction(Payload))
    }, [allAgentReload])


    // export on click
    const exportOnClick = () => {

        // data for export
        const dataForExport = []
        filteredAgentData.map(singleAgent => {
            let extinsibleObj = { ...singleAgent }
            // singleAgent.total_member = "ha"ø
            extinsibleObj.total_member = singleAgent.members[0]?.total_member || 0
            delete extinsibleObj.members
            // console.log(singleAgent.members[0]?.total_member || 0)
            // console.log(a)
            dataForExport.push(extinsibleObj)
        })

        // console.log(dataForExport)
        let wb = XLSX.utils.book_new()
        let ws = XLSX.utils.json_to_sheet(dataForExport)
        XLSX.utils.book_append_sheet(wb, ws, "allAgent")
        XLSX.writeFile(wb, "allAgent.xlsx")
    }


    // download excel for add agent
    const downloadExcelForAddAgent = () => {
        let userDetail = JSON.parse(Auth.header().userdetail || "{}")
        let wb = XLSX.utils.book_new()
        let ws = XLSX.utils.json_to_sheet([{
            full_name: "",
            mobile: '',
            email: "",
            collection_area_district: "",
            collection_area_police_station: "",
            collection_area_post_office: "",
            collection_area_village: "",
            user_name: "",
            password: "",
            balance: "",
            created_user: userDetail.user_name
        }])
        XLSX.utils.book_append_sheet(wb, ws, "allAgent")
        XLSX.writeFile(wb, "agentImportSheet.xlsx")
    }

    return (
        <>
            <AgentContext.Provider value={{
                agentAddModalShow,
                setAgentAddModalShow,
                setAllAgentReload,
                setAgentEditModalShow,
                getSingleAgentData,
                setGetSingleAgentData,
                setAddWalletModelShow,
                setChangePasswordModelShow,
                setImportModalShow

            }}>
                <Card>
                    <Card.Header className="d-flex align-items-center justify-content-between">
                        <ComponentIdentifier link_name={"agent"} component_name={""} />
                        <div className="d-flex align-items-center justify-content-between">
                            {HeaderComponent.refresh([setAllAgentReload])}
                            {HeaderComponent.add(setAgentAddModalShow)}
                        </div>
                    </Card.Header>
                    <Card.Body>
                        <div className="position-relative">
                            <Row>
                                <Col lg={6} md={6} sm={12} className="searchArea">
                                    {/*agent search*/}
                                    <CommonSearch
                                        preFilterData={allAgent}
                                        setFilteredDataState={setFilteredAgentData}
                                        placeHolder={"Search Name / Village / Phone No."}
                                        propertyForMatch={["full_name", "collection_area_village", "mobile"]}
                                    />
                                </Col>
                                <Col lg={6} md={6} sm={12} className="mt-1">
                                    <Button
                                        className="me-2 btn-sm"
                                        variant="success"
                                        onClick={e => downloadExcelForAddAgent()}
                                    ><AiOutlineDownload /> Download Excel</Button>
                                    <Button
                                        className="me-2 btn-sm"
                                        variant="info"
                                        onClick={e => exportOnClick()}
                                    ><AiOutlineDownload /> Export all agent in excel</Button>
                                    <Button
                                        className="btn-sm"
                                        variant="primary"
                                        onClick={e => setImportModalShow(true)}><GrDocumentUpload /> Upload
                                        Agent</Button>
                                </Col>
                            </Row>

                            <AgentTable data={filteredAgentData} />
                            <SectionLoader loading_handle_ref={allAgentTableLoaderRef} />
                        </div>
                        {/*add modal show*/}
                        <CommonModal
                            show={agentAddModalShow}
                            setShow={setAgentAddModalShow}
                            title={"Add agent"}
                            size="lg"

                        >
                            <AgentForm />
                        </CommonModal>
                        {/*Add Wallet Modal*/}
                        <CommonModal
                            show={addWalletModelShow}
                            setShow={setAddWalletModelShow}
                            title={"Add Wallet"}
                        >
                            <AddWalletForm />
                        </CommonModal>
                        {/*edit agent modal*/}
                        <CommonModal
                            show={agentEditModalShow}
                            setShow={setAgentEditModalShow}
                            title={"Edit Agent"}
                            size="lg"
                        >
                            <AgentForm formType={'Update'} />
                        </CommonModal>

                        {/*Change Password Modal*/}
                        <CommonModal
                            show={changePasswordModelShow}
                            setShow={setChangePasswordModelShow}
                            title={"Change Password"}
                        >
                            <ChangePasswordForm />
                        </CommonModal>
                        {/*import modal show*/}
                        <CommonModal
                            show={importModalShow}
                            setShow={setImportModalShow}
                            title={"Import agent"}
                            size="md"

                        >
                            <AgentImport />
                        </CommonModal>
                    </Card.Body>
                </Card>

            </AgentContext.Provider>
        </>
    );
}

export default AgentComponent;