import React from 'react';
import Auth from "../../Utilities/Auth";
import {Link, useNavigate} from "react-router-dom";
import {FaUserCircle} from "@react-icons/all-files/fa/FaUserCircle";
import {Button} from "react-bootstrap";
import NumberSeparator from "../../Utilities/NumberSeparator";
import {HiUsers} from "@react-icons/all-files/hi/HiUsers";
import Logo from "../../assets/images/logo.png"
import {useSelector} from "react-redux";

function TopNav(props) {
    // hooks
    const navigate = useNavigate()
    // let data from auth
    let agentDetail = JSON.parse(Auth.header().userdetail || "{}")
    // getting data form store
    const totalAmountForCollection = useSelector(state => state.memberSlice.totalAmountForCollection)
    const users_total_collection = useSelector(state => state.memberSlice.users_total_collection)
    // logout on click
    const logoutOnClick = () => {
        Auth.clear()
    }
    return (
        <>
            <nav className="sb-topnav navbar navbar-expand navbar-dark">


                <form className="d-none d-md-inline-block form-inline ms-auto me-0 me-md-3 my-2 my-md-0"></form>
                {/* Navbar*/}
                {/*wallet amount*/}
                {agentDetail.is_admin == 0 && <div
                    className="wallet me-1"><span>Wallet : </span>{NumberSeparator.format(agentDetail.balance)}</div>}
                {/*show toal due*/}
                {agentDetail.is_admin == 1 && <div
                    className=" me-1">
                    <span>{NumberSeparator.format(totalAmountForCollection, " - ")}</span>
                    <span>{NumberSeparator.format(users_total_collection, " = ")}</span>
                    <span>{NumberSeparator.format(totalAmountForCollection - users_total_collection,)}</span>
                </div>}

                {/*user login log out dropdown*/}
                <ul className="navbar-nav ms-auto ms-md-0 me-3 me-lg-4">
                    <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle" id="navbarDropdown" href="#" role="button"
                           data-bs-toggle="dropdown" aria-expanded="falsex">
                            {agentDetail.is_admin == 0 ? <span
                                className="text-muted me-1"> 
                                {agentDetail.avatar == null ? <FaUserCircle className="userIcon"/> :
                                <img src={agentDetail.avatar} className="userImg"/>}
                            </span> : <span
                                className="text-muted me-1"> 
                               {/*<FaUserCircle className="userIcon"/> */}
                                <img src={Logo} width="35px"/>
                                </span>}</a>

                        <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                            <li className="text-center overflow-hidden text-muted text-center">{JSON.parse(Auth.header().userdetail || "{}").full_name}</li>
                            <li>
                                <hr className="dropdown-divider"/>
                            </li>
                            <li><Link to={"/setting"} className="dropdown-item" href="#!">Settings</Link></li>
                            <li>
                                <hr className="dropdown-divider"/>
                            </li>

                            {/*logout button*/}
                            <li className="px-2"><Button
                                className="dropdown-item bg-danger text-white fw-bold text-center" onClick={e => {
                                logoutOnClick()
                            }} style={{cursor: "pointer"}}>Logout</Button></li>
                        </ul>
                    </li>
                </ul>
            </nav>
        </>
    );
}

export default TopNav;