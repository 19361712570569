import React from 'react';
import AgentCollectionHistoryComponent
    from "../Component/AgentCollectionHistoryComponent/AgentCollectionHistoryCoponent";


function AgentCollectionHistoryPage(props) {
    return (
        <>
            <AgentCollectionHistoryComponent/>
        </>
    );
}

export default AgentCollectionHistoryPage;