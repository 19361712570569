import CommonAxios from "../../Utilities/CommonAxios";
import {VendorSlice} from "../Slice/VendorSlice";
import {AgentSlice} from "../Slice/AgentSlice";
import {
    addAgent,
    agentBalancePayment, getAgentCollectionHistory, getAgentWalletHistory,
    getAllAgent, importNewAgent,
    resetAgentPasswordFromAdmin,
    updateAgent
} from "../../AllRoutes/ApiRoute";
import CommonLoader from "../../Utilities/CommonLoader";
import CommonToast from "../../Utilities/CommonToast";

export const getAllAgentAction = payload => dispatch => {
    // getting data from payload
    const {allAgentTableLoaderRef} = payload
    //loader handle
    CommonLoader.section(allAgentTableLoaderRef)
    CommonAxios.get(getAllAgent).then(res => {
        //loader handle
        CommonLoader.section(allAgentTableLoaderRef)
        if (res.data.status == true) {
            dispatch(AgentSlice.actions.getAllAgentReducer(res.data.data))
        }

    }).catch(err => {
        //loader handle
        CommonLoader.section(allAgentTableLoaderRef)
    })


}


// add agent action
export const addAgentAction = payload => dispatch => {
    const {
        btnRef,
        setAgentAddModalShow,
        setAllAgentReload

    } = payload

    // handle button loader
    CommonLoader.btn(btnRef)


    CommonAxios.formDataPost(addAgent, payload).then(res => {
        // handle button loader
        CommonLoader.btn(btnRef)

        if (res.data.status == true) {
            // modal close
            setAgentAddModalShow(false)
            //all Agent table reload
            setAllAgentReload(prev => !prev)
            // toast
            CommonToast.success("Agent Create Successfully!")
        } else {
            CommonToast.error("Agent Create failed!")
        }

    }).catch(err => {
        // handle button loader
        CommonLoader.btn(btnRef)
    })

}

// import agent action
export const importAgentAction = payload => dispatch => {
    const {
        submitRef,
        setAllAgentReload,
        setImportModalShow
    } = payload

    // handle button loader
    CommonLoader.btn(submitRef)


    CommonAxios.post(importNewAgent, payload).then(res => {
        // handle button loader
        CommonLoader.btn(submitRef)

        if (res.data.status == true) {
            // modal close
            setImportModalShow(false)
            //all Agent table reload
            setAllAgentReload(prev => !prev)
            // toast
            CommonToast.success("Agent imported Successfully!")
        } else {
            CommonToast.error("Agent imported failed!")
        }

    }).catch(err => {
        // handle button loader
        CommonLoader.btn(submitRef)
    })

}

// update vendor action
export const updateAgentAction = payload => dispatch => {
    const {
        btnRef,
        setAgentEditModalShow,
        setAllAgentReload

    } = payload

    // handle button loader
    CommonLoader.btn(btnRef)


    CommonAxios.formDataPost(updateAgent, payload).then(res => {
        // handle button loader
        CommonLoader.btn(btnRef)

        if (res.data.status == true) {
            // modal close
            setAgentEditModalShow(false)
            //all Agent table reload
            setAllAgentReload(prev => !prev)
            // toast
            CommonToast.success("Agent updated Successfully!")
        } else {
            CommonToast.warning("Agent updated failed! " + res.data?.error_message)
        }

    }).catch(err => {
        // handle button loader
        CommonLoader.btn(btnRef)
    })

}


// Add Wallet action
export const addWalletAction = payload => dispatch => {
    const {
        addWalletRef,
        setAddWalletModelShow,
        setAllAgentReload,
    } = payload


    // handle button loader
    CommonLoader.btn(addWalletRef)

    CommonAxios.post(agentBalancePayment, payload).then(res => {
        CommonLoader.btn(addWalletRef)
        if (res.data.status == true) {
            setAllAgentReload(prev => prev + 1)
            setAddWalletModelShow(false)
            CommonToast.success("wallet added successfully")
        } else {
            CommonToast.error("balance added failed.")
        }
    }).catch(err => {
        CommonLoader.btn(addWalletRef)
    })
}


// Change Password action
export const changePasswordAction = payload => dispatch => {
    const {
        changePasswordRef,
        setChangePasswordModelShow,
        setAllAgentReload,

    } = payload

    // handle button loader
    CommonLoader.btn(changePasswordRef)


    CommonAxios.post(resetAgentPasswordFromAdmin, payload).then(res => {
        // handle button loader
        CommonLoader.btn(changePasswordRef)

        if (res.data.status == true) {
            // modal close
            setChangePasswordModelShow(false)
            //all Agent table reload
            setAllAgentReload(prev => !prev)
            // toast
            CommonToast.success("Password Change Successfully!")
        } else {
            CommonToast.error("Password Change Add Failed!")
        }

    }).catch(err => {
        // handle button loader
        CommonLoader.btn(changePasswordRef)
    })

}

// Agent Wallet History action
export const agentWalletHistoryAction = payload => dispatch => {
    const {
        btnRef,
        setAgentWalletHistory
    } = payload

    // handle button loader
    CommonLoader.btn(btnRef)

    // db operation
    CommonAxios.post(getAgentWalletHistory, payload).then(res => {
        // handle button loader
        CommonLoader.btn(btnRef)

        if (res.data.status == true) {
            setAgentWalletHistory(res.data.data)


        } else {
            // CommonToast.error("balance added failed.")
        }
    }).catch(err => {
        // handle button loader
        CommonLoader.btn(btnRef)
    })
}


// Agent Collection History action
export const agentCollectionHistoryAction = payload => dispatch => {
    const {
        btnRef,
        setAgentCollectionHistory
    } = payload

    // handle button loader
    CommonLoader.btn(btnRef)

    // db operation
    CommonAxios.post(getAgentCollectionHistory, payload).then(res => {
        // handle button loader
        CommonLoader.btn(btnRef)

        if (res.data.status == true) {
            setAgentCollectionHistory(res.data.data)


        } else {
            // CommonToast.error("balance added failed.")
        }
    }).catch(err => {
        // handle button loader
        CommonLoader.btn(btnRef)
    })
}


