import CommonAxios from "../../Utilities/CommonAxios";
import {
    addMember,
    donationCollection,
    getAgentWiseMember,
    getAllMember, getAllMembersForExport,
    getTotalDue, importMember,
    updateMember
} from "../../AllRoutes/ApiRoute";
import CommonLoader from "../../Utilities/CommonLoader";
import {MemberSlice} from "../Slice/MemberSlice";
import CommonToast from "../../Utilities/CommonToast";
import axios from "axios";
import {membersExportMethod} from "../../Component/AllMemberComponent/Utilities/MembersExportMethod";


// get members total due
export const getMembersTotalDue = payload => dispatch => {
    // db operation
    CommonAxios.get(getTotalDue).then(res => {
        if (res.data.status == true) {
            dispatch(MemberSlice.actions.getTotalDueReducer(res.data))
        }
    }).catch(err => {
        console.log(err)
    })
}

export const getAllMemberAction = payload => dispatch => {
    // getting data from payload
    const {
        setDataForTable,
        pageConfigurationFromCommonPagination
    } = payload

    try {
        // disable button
        pageConfigurationFromCommonPagination.pagesDisable()
    } catch (e) {

    }

    // db operation
    CommonAxios.post(getAllMember, payload).then(res => {
        try {
            // enable button
            pageConfigurationFromCommonPagination.pagesEnable()
        } catch (e) {

        }
        if (res.data.status === true) {
            // data set
            setDataForTable(res.data);
        }
    }).catch(err => {
        try {
            // enable button
            pageConfigurationFromCommonPagination.pagesEnable()
        } catch (e) {

        }
        console.log(err)
    })
}

export const getAgentWiseMemberAction = payload => dispatch => {
    // getting data from payload
    const {
        setDataForTable,
        pageConfigurationFromCommonPagination
    } = payload


    CommonAxios.post(getAgentWiseMember, payload).then(res => {
        try {
            // enable button
            pageConfigurationFromCommonPagination.pagesEnable()
        } catch (e) {

        }
        if (res.data.status === true) {
            // data set
            setDataForTable(res.data);
        }
    }).catch(err => {
        try {
            // enable button
            pageConfigurationFromCommonPagination.pagesEnable()
        } catch (e) {

        }
        console.log(err)
    })
}


// add member action
export const addMemberAction = payload => dispatch => {
    const {
        btnRef,
        setAddMemberModelShow,
        setAllMemberReload,
        setMemberIdCardModelShow,
        setIdData

    } = payload

    // handle button loader
    CommonLoader.btn(btnRef)


    CommonAxios.post(addMember, payload).then(res => {
        // handle button loader
        CommonLoader.btn(btnRef)

        if (res.data.status === true) {
            // modal close
            setAddMemberModelShow(false)
            //all Agent table reload
            setAllMemberReload(prev => !prev)
            // toast
            CommonToast.success("Member Create Successfully!")
            // id data set
            setIdData(res.data.data)
            // id Model Open
            setMemberIdCardModelShow(true)

        } else {
            CommonToast.error("Member Create failed!")
        }

    }).catch(err => {
        // handle button loader
        CommonLoader.btn(btnRef)
    })

}

// edit Member action
export const editMemberAction = payload => dispatch => {
    const {
        btnRef,
        setEditMemberModelShow,
        setAllMemberReload,
        setGetSingleMemberData,
        reset
    } = payload

    // handle button loader
    CommonLoader.btn(btnRef)


    CommonAxios.post(updateMember, payload).then(res => {
        // handle button loader
        CommonLoader.btn(btnRef)

        if (res.data.status === true) {
            // single data close
            setGetSingleMemberData({})
            // reset form
            reset()
            // modal close
            setEditMemberModelShow(false)
            //all Agent table reload
            setAllMemberReload(prev => !prev)
            // toast
            CommonToast.success("Member Update Successfully!")
        } else {
            CommonToast.error("Member Update failed!")
        }

    }).catch(err => {
        // handle button loader
        CommonLoader.btn(btnRef)
    })

}

// Member Collection action
export const memberCollectionAction = payload => dispatch => {
    const {
        btnRef,
        setDonationModelShow,
        setAllMemberReload,
        yearForPayment,
        setYearForPayment,
        paymentIndexForUpdate
    } = payload

    // handle button loader
    CommonLoader.btn(btnRef)


    CommonAxios.post(donationCollection, payload).then(res => {
        // handle button loader
        CommonLoader.btn(btnRef)

        if (res.data.status === true) {
            let newYForPayment = [...yearForPayment]
            newYForPayment[paymentIndexForUpdate].payment = {
                collection_year: payload.data.collection_year,
                collection_amount: payload.data.collection_amount,

            }
            setYearForPayment(newYForPayment)
            // //all Agent table reload
            // setAllMemberReload(prev => !prev)
            // toast
            CommonToast.success("Member Collection Successfully!")
        } else {
            CommonToast.error("Member Collection failed!")
        }

    }).catch(err => {
        // handle button loader
        CommonLoader.btn(btnRef)
    })
}

// import member action
export const importMemberAction = payload => dispatch => {
    // getting data form payload
    const {
        setImportMemberModal,
        setAllMemberReload,
        submitRef
    } = payload
    // handle button loader
    CommonLoader.btn(submitRef)
    // db operation
    CommonAxios.post(importMember, payload).then(res => {
        // handle button loader
        CommonLoader.btn(submitRef)
        if (res.data.status == true) {
            setImportMemberModal(false)
            setAllMemberReload(prev => !prev)
            CommonToast.success("Members imported successfully.")
        } else {
            CommonToast.error("Members imported failed.")
        }
    }).catch(err => {
        // handle button loader
        CommonLoader.btn(submitRef)
    })
}

// get all members for export action
export const getAllMembersForExportAction = payload => dipsatch => {
    // getting data form payload
    const {membersExportRef} = payload
    // handle button loader
    CommonLoader.btn(membersExportRef)
    // db operation
    CommonAxios.post(getAllMembersForExport, payload).then(res => {
        // handle button loader
        CommonLoader.btn(membersExportRef)
        if (res.data.status == true) {
            // members export
            membersExportMethod(res.data.data)
        } else {
            CommonToast.error("members export failed.")
        }
    }).catch(err => {
        // handle button loader
        CommonLoader.btn(membersExportRef)
    })
}
