import React, {useEffect} from 'react';
import TopNav from "../../Component/NavBar/TopNav";
import SideBarAndContentWrapper from "../../Component/Wrapper/SideBarAndContentWrapper";
import SideBar from "../../Component/NavBar/SideBar";
import ContentWrapper from "../../Component/Wrapper/ContentWrapper";
import Auth from "../../Utilities/Auth";
import {useLocation, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import SideNavUtilities from "../../Component/NavBar/Utilities/SideNavUtilities";
import {Container} from "react-bootstrap";
import {getMembersTotalDue} from "../../Redux/Action/MemberAction";

function ProtectedRoute(props) {
    const {permissions = ["*"]} = props
    // hooks
    const navigate = useNavigate()
    const location = useLocation()
    const dispatch = useDispatch()
    let auth = Auth.header()
    const isLogged = useSelector(state => state.authSlice.isLogged)
    const isPermitted = new SideNavUtilities().isPermitted([...permissions])
    const sessionIsChanged = useSelector(state => state.authSlice.sessionIsChanged)


    // auth check lifecycle
    useEffect(() => {
        if (auth == false && isLogged == false) {
            Auth.clear()
            navigate("/login")
        }
    }, [location, isLogged])

    // is permitted page or not check lifecycle
    useEffect(() => {
        if (isPermitted == false) {
            navigate("/")
        }
    }, [location, isPermitted, sessionIsChanged])

    // get total due lifecycle
    useEffect(() => {
        if (auth != false && isLogged != false) {
            dispatch(getMembersTotalDue({}))
        }
    }, [location, isLogged])


    // check user logged in or not
    if (auth != false) {
        return (
            <>
                <TopNav/>
                {/*side bar and content wrapper*/}
                <SideBarAndContentWrapper>
                    <SideBar/>
                    {/*only content wreapper*/}
                    <ContentWrapper>
                        <Container className="mt-4" fluid={true}>
                            {/*check permitted or not*/}
                            {isPermitted == true && props.children}
                        </Container>
                    </ContentWrapper>
                </SideBarAndContentWrapper>
            </>
        );
    } else {
        return <></>
    }

}

export default ProtectedRoute;