import React, {useContext, useRef, useState} from 'react';
import {Form, Button} from "react-bootstrap";
import * as XLSX from 'xlsx'
import Auth from "../../../Utilities/Auth";
import {useDispatch} from "react-redux";
import ButtonLoader from "../../CommonComponent/ButtonLoader/ButtonLoader";
import {AgentContext} from "../AgentComponent";
import {importAgentAction} from "../../../Redux/Action/AgentAction";

function AgentImport(props) {
    // hooks
    const dispatch = useDispatch()
    const submitRef = useRef()
    // states
    const [excelFile, setExcelFile] = useState(null);
    const [excelFileError, setExcelFileError] = useState(null);

    // getting data from context
    const {setAllAgentReload, setImportModalShow} = useContext(AgentContext)


    // it will contain array of objects

    // handle File
    const fileType = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
    const handleFile = (e) => {
        let selectedFile = e.target.files[0];
        if (selectedFile) {
            // console.log(selectedFile.type);
            if (selectedFile && fileType.includes(selectedFile.type)) {
                let reader = new FileReader();
                reader.readAsArrayBuffer(selectedFile);
                reader.onload = (e) => {
                    setExcelFileError(null);
                    setExcelFile(e.target.result);
                }
            } else {
                setExcelFileError('Please select only excel file types');
                setExcelFile(null);
            }
        } else {
            console.log('plz select your file');
        }
    }

    // submit function
    const handleSubmit = (e) => {
        e.preventDefault();
        let userDetail = JSON.parse(Auth.header().userdetail || "{}")
        if (excelFile !== null) {
            const workbook = XLSX.read(excelFile, {type: 'buffer'});
            const worksheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[worksheetName];
            const data = XLSX.utils.sheet_to_json(worksheet);

            // set created by
            const newData = data.filter(singleData => {
                singleData.created_by = userDetail.id
                return singleData
            })
            // making payload for import agents
            const payload = {
                data: {agentDatailArr: newData},
                submitRef,
                setAllAgentReload,
                setImportModalShow
            }
            // action dispatch
            dispatch(importAgentAction(payload))

        } else {

        }
    }
    return (
        <>
            <Form onSubmit={e => handleSubmit(e)}>
                {/*vendor name field*/}
                <Form.Group className="mb-2" controlId="formBasicEmail">
                    <Form.Label>Upload agents</Form.Label>
                    <Form.Control type="file" required={true} onChange={e => handleFile(e)}/>
                    {excelFileError && <div className='text-danger'
                                            style={{marginTop: 5 + 'px'}}>{excelFileError}</div>}
                </Form.Group>

                <Button ref={submitRef} variant="success" disabled={false} className="mt-2 btn-sm" type="submit">
                    import
                    <ButtonLoader/>
                </Button>
                <br/>
            </Form>
        </>
    );
}

export default AgentImport;