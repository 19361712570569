import React, {useContext} from 'react';
import {useSelector} from "react-redux";
import BootstrapTable from 'react-bootstrap-table-next';
import TableTwoBody from "../../CommonComponent/BootstrapTable2Body/TableTwoBody";
import data from "bootstrap/js/src/dom/data";
import {FiEdit} from "@react-icons/all-files/fi/FiEdit";
import {AgentContext} from "../AgentComponent";

import {MdPayment} from "@react-icons/all-files/md/MdPayment";
import {CgPassword} from "@react-icons/all-files/cg/CgPassword";
import {FaUserAlt} from "@react-icons/all-files/fa/FaUserAlt";


function AgentTable({data = []}) {


    //Context
    const {
        setAgentEditModalShow,
        setGetSingleAgentData,
        setAddWalletModelShow,
        setChangePasswordModelShow
    } = useContext(AgentContext)

    const columns = [{
        dataField: 'id',
        text: 'SL',
        sort: true,
        headerStyle: (colum, colIndex) => {
            return {
                width: '60px',
            };
        },
        formatter: (cell, row, rowIndex) => {
            return <>
                {rowIndex + 1}
            </>
        }
    }, {
        dataField: 'id',
        text: 'Agent id',
        sort: true,
        headerStyle: (colum, colIndex) => {
            return {
                width: '90px',
            };
        },
        formatter: (cell, row, rowIndex) => {
            return <>
                {cell}
            </>
        }
    }, {
        dataField: 'avatar',
        text: 'Image',
        sort: true,
        headerStyle: (colum, colIndex) => {
            return {
                width: '90px',
            };
        },
        formatter: ((cell, row) => {
            return <>
                {cell != null ?
                    <img style={{width: "60px", height: "40px"}} src={cell} alt={"no file"} className="img-fluid"/> :
                    <div className="d-flex align-items-center justify-content-center">
                        <FaUserAlt className="m-auto text-muted"/>
                    </div>}
            </>
        })
    },
        {
            dataField: 'full_name',
            text: 'Name',
            sort: true,
            headerStyle: (colum, colIndex) => {
                return {
                    width: '200px',
                };
            },
            formatter: ((cell, row) => {
                return <>
                    {cell + " (" + row.id + ")"}
                </>
            })
        }, {
            dataField: 'mobile',
            text: 'Phone No.',
            sort: false,
            headerStyle: (colum, colIndex) => {
                return {
                    width: '150px',
                };
            }
        }, {
            dataField: 'collection_area_village',
            text: 'Total member',
            sort: true,
            headerStyle: (colum, colIndex) => {
                return {
                    width: '150px',
                };
            },
            formatter: (cell, row) => {
                return row.members[0]?.total_member || 0
            }
        }, {
            dataField: 'collection_area_village',
            text: 'Village',
            sort: true,
            headerStyle: (colum, colIndex) => {
                return {
                    width: '150px',
                };
            }
        }, {
            dataField: 'collection_area_district',
            text: 'District',
            sort: true,
            headerStyle: () => {
                return {
                    width: '120px'
                }
            }
        }, {
            dataField: 'collection_area_police_station',
            text: 'P.S',
            sort: true,
            headerStyle: () => {
                return {
                    width: '120px'
                }
            }
        }, {
            dataField: 'collection_area_post_office',
            text: 'P.O',
            sort: true,
            headerStyle: () => {
                return {
                    width: '120px'
                }
            }
        }, {
            dataField: 'user_name',
            text: 'User Name',
            sort: true,
            headerStyle: () => {
                return {
                    width: '120px'
                }
            }
        }, {
            dataField: 'balance',
            text: 'Balance',
            sort: true,
            headerStyle: () => {
                return {
                    width: '80px'
                }
            }
        }, {
            dataField: 'id',
            text: 'Action',
            headerStyle: () => {
                return {
                    width: '120px',
                }
            },
            style: {textAlign: "center"},
            formatter: ((cell, row) => {
                return <>
                    {/*Change Password*/}
                    <button title="Change Password"
                            className="bg-transparent iconActionButton border-0 text-success btn btn-primary"
                            onClick={e => {
                                // data for update
                                setGetSingleAgentData(row)
                                // update modal show
                                setChangePasswordModelShow(true)
                            }
                            }>
                        <CgPassword/>
                    </button>
                    {/*Add Wallet*/}
                    <button title="ADD WALLET"
                            className="bg-transparent iconActionButton border-0 text-success btn btn-primary"
                            onClick={e => {
                                // data for update
                                setGetSingleAgentData(row)

                                // Add Wallet modal show
                                setAddWalletModelShow(true)

                            }
                            }>
                        <MdPayment/>
                    </button>

                    {/*edit Button*/}
                    <button title="EDIT"
                            className="bg-transparent iconActionButton border-0 text-success btn btn-primary"
                            onClick={e => {
                                // data for update
                                setGetSingleAgentData(row)
                                // update modal show
                                setAgentEditModalShow(true)
                            }
                            }>
                        <FiEdit/>
                    </button>


                </>
            })

        }

    ];


    return (
        <>
            <TableTwoBody
                products={data}
                columns={columns}
                isBorderd={true}
            />
        </>
    );
}

export default AgentTable;