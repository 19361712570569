import React, { useEffect, useState } from 'react';
import {
    Routes,
    Route,
} from "react-router-dom";
import DashboardPage from "../Pages/DashboardPage";
import LoginPage from "../Pages/LoginPage";
import { Navigate } from "react-router";
import ProtectedRoute from "./Utilities/ProtectedRoute";
import AgentPage from "../Pages/AgentPage";
import AllMemberPage from "../Pages/AllMemberPage";
import MyMemberPage from "../Pages/MyMemberPage";
import Auth from "../Utilities/Auth";
import AgentWalletHistoryPage from "../Pages/AgentWallet HistoryPage";
import AgentCollectionHistoryPage from "../Pages/AgentCollectionHistoryPage";
import UserSettingPage from "../Pages/UserSettingPage";
import ReportPage from '../Pages/ReportPage';

function AppRoute(props) {

    return (
        <>
            <Routes>
                <Route path='/' element={<Navigate to={'/dashboard'} />} />
                {/*login route*/}
                <Route path='/login' element={<LoginPage />} />
                {/*dashboard route*/}
                <Route path='/dashboard' element={<ProtectedRoute permissions={["*"]}>
                    <DashboardPage />
                </ProtectedRoute>} />
                <Route path='/agent' element={<ProtectedRoute permissions={["is_admin"]}>
                    <AgentPage />
                </ProtectedRoute>} />
                <Route path='/all-member' element={<ProtectedRoute permissions={["is_admin"]}>
                    <AllMemberPage />
                </ProtectedRoute>} />
                <Route path='/report' element={<ProtectedRoute permissions={["is_admin"]}>
                    <ReportPage />
                </ProtectedRoute>} />
                <Route path='/agent-history' element={<ProtectedRoute permissions={["is_admin"]}>
                    <AgentWalletHistoryPage />
                </ProtectedRoute>} />
                <Route path='/agent-collection-history' element={<ProtectedRoute permissions={["is_admin"]}>
                    <AgentCollectionHistoryPage />
                </ProtectedRoute>} />
                <Route path='/my-member' element={<ProtectedRoute permissions={["*"]}>
                    <MyMemberPage />
                </ProtectedRoute>} />
                <Route path='/setting' element={<ProtectedRoute
                    permissions={["*"]}>
                    <UserSettingPage />
                </ProtectedRoute>} />


            </Routes>
        </>
    )
        ;
}

export default AppRoute;