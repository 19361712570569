import React, { useContext, useEffect, useState } from 'react';
import TableTwoBody from "../../CommonComponent/BootstrapTable2Body/TableTwoBody";
import { CgPassword } from "@react-icons/all-files/cg/CgPassword";
import { MdPayment } from "@react-icons/all-files/md/MdPayment";
import { FiEdit } from "@react-icons/all-files/fi/FiEdit";
import { MemberContext } from "../AllMemberComponent";
import NumberSeparator from "../../../Utilities/NumberSeparator";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import CommonAxios from "../../../Utilities/CommonAxios";
import Auth from "../../../Utilities/Auth";
import { getAgentWiseMemberAction, getAllMemberAction } from "../../../Redux/Action/MemberAction";
import { useDispatch } from "react-redux";
import { Button } from "react-bootstrap";
import CommonPagination from "../../CommonComponent/CommonPagination/CommonPagination";
import Parse from "../../../Utilities/parse";
import MemberTableConfig from "../Utilities/MemberTableConfig";

function MemberTable() {
    //Context
    const {
        setMemberIdCardModelShow,
        setIdData,
        searchedKey,
        allMemberReload, donationModelShow,
        setAllMemberReload,
        setFilteredMemberData,
        setEditMemberModelShow,
        setGetSingleMemberData,
        setDonationModelShow,
        currentPage,
        setCurrentPage,
        member_barcode
    } = useContext(MemberContext)

    const dispatch = useDispatch()

    const [dataForTable, setDataForTable] = useState({});
    const [pageConfigurationFromCommonPagination, setPageConfigurationFromCommonPagination] = useState({})

    // data get lifecycle
    useEffect(() => {
        memberFetch()
    }, [currentPage, allMemberReload, donationModelShow, pageConfigurationFromCommonPagination]);

    // data fetch
    const memberFetch = () => {
        const Payload = {
            data: {
                search_key: searchedKey,
                skip: (currentPage - 1) * 50,
                limit: 50,
                member_barcode
            },
            setDataForTable,
            pageConfigurationFromCommonPagination
        }
        if (donationModelShow == false && Object.keys(pageConfigurationFromCommonPagination).length != 0) {
            let permission = JSON.parse(Auth.header().userdetail || "{}")
            permission.is_admin ? dispatch(getAllMemberAction(Payload)) : dispatch(getAgentWiseMemberAction(Payload))
        }
    }

    // column and column initial
    const columns = MemberTableConfig.allColumn({
        setMemberIdCardModelShow,
        setIdData,
        currentPage,
        setDonationModelShow,
        setEditMemberModelShow,
        setGetSingleMemberData
    });


    return (
        <>
            <BootstrapTable
                wrapperClasses={'table-responsive'}
                headerClasses={"tableTwoHeader"}
                keyField="id"
                data={dataForTable.data || []}
                columns={columns}
            />
            <CommonPagination
                setPageConfigurationFromCommonPagination={setPageConfigurationFromCommonPagination}
                totalPage={Math.ceil((Parse.toNumber(dataForTable.totalRowCount) / 50) || 0)}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
            />
        </>
    );
}

export default MemberTable;