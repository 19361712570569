import {createSlice} from "@reduxjs/toolkit";

export const AgentSlice = createSlice({
    name:"AgentSlice",
    initialState:{
        allAgent: []
    },
    reducers:{
        // get all Agent reducer
        getAllAgentReducer:(state,action)=>{
            state.allAgent = action.payload

        }
    }
})