import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import './TableTwoCss.css'

function TableTwoBody({products = [], columns = [], isBorderd = false, is_pagination = true}) {
    const options = {
        showTotal: false,
        disablePageTitle: false,
        sizePerPageList: [{
            text: '50', value: 50
        },
        //     {
            //     text: '100', value: 100
            // }, {
            //     text: '125', value: 125
            // },
        ],

    };
    return (
        <>
            <BootstrapTable
                wrapperClasses={'table-responsive'}
                headerClasses={"tableTwoHeader"}
                keyField={'1'}
                data={products}
                columns={columns}
                pagination={is_pagination ? paginationFactory(options) : false}
                striped={true}
                condensed
                bordered={isBorderd}
            />
        </>
    );
}

export default TableTwoBody;