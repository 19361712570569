import React, {createContext, useState} from 'react';
import {Card} from "react-bootstrap";
import ComponentIdentifier from "../CommonComponent/ComponentHeader/ComponentIdentifier";
import WalletHistoryForm from "../AgentWalletHistoryComponent/SubComponent/WalletHistoryForm";
import WalletHistoryTable from "../AgentWalletHistoryComponent/SubComponent/WalletHistoryTable";
import CollectionHistoryForm from "./SubComponent/CollectionHistoryForm";
import CollectionHistoryTable from "./SubComponent/CollectionHistoryTable";
// Context
export const AgentCollectionHistoryContext = createContext()

function AgentCollectionHistoryComponent(props) {
    //state
    const [agentCollectionHistory,setAgentCollectionHistory] = useState([])
    return (
        <>
            <AgentCollectionHistoryContext.Provider value={{
                agentCollectionHistory,
                setAgentCollectionHistory
            }}>
                <Card>
                    <Card.Header className="d-flex align-items-center justify-content-between">
                        <ComponentIdentifier link_name={"Agent Collection History"} component_name={""}/>
                    </Card.Header>
                    <Card.Body>
                        <CollectionHistoryForm/>
                        <CollectionHistoryTable/>
                    </Card.Body>
                </Card>
            </AgentCollectionHistoryContext.Provider>

        </>
    );
}

export default AgentCollectionHistoryComponent;