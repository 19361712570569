class Parse {
    constructor(props) {
    }

    static toNumber(num = 0) {
        return Number(num) || 0
    }

}

// export
export default Parse