import React, {useContext, useEffect, useRef, useState} from 'react';
import {Button, Col, Form, Row} from "react-bootstrap";
import {useForm} from "react-hook-form";
import {addAgentAction, updateAgentAction} from "../../../Redux/Action/AgentAction";
import CommonDatePicker from "../../CommonComponent/DatePickerComponent/CommonDatePicker";
import Regex from "../../../Utilities/Regex";
import {MemberContext} from "../AllMemberComponent";
import {useDispatch} from "react-redux";
import {addMemberAction, editMemberAction} from "../../../Redux/Action/MemberAction";
import Auth from "../../../Utilities/Auth";

function MemberForm({formType = "add"}) {
    const {
        setAllMemberReload,
        setAddMemberModelShow,
        getSingleMemberData,
        setEditMemberModelShow,
        setGetSingleMemberData,
        memberIdCardModelShow,
        setMemberIdCardModelShow,
        setIdData
    } = useContext(MemberContext)

    // ref
    const btnRef = useRef()
    const dispatch = useDispatch()
    // states
    const [dob, setDob] = useState(null)
    // getting auth data
    let agentDetail = JSON.parse(Auth.header().userdetail || "{}")
    //Hook Form
    const {register, handleSubmit, getValues, reset, setValue, formState: {errors}} = useForm();

    useEffect(() => {
        if (Object.keys(getSingleMemberData).length != 0) {
            Object.keys(getValues()).map(fieldProp => {
                setValue(fieldProp, getSingleMemberData[fieldProp])
            })
        } else {
            if (agentDetail.is_admin == 0) {
                setValue("agent_id", agentDetail.id)
            }
        }
    })

    const onSubmit = data => {
        const payload = {
            data,
            reset,
            btnRef,
            setAllMemberReload
        }

        if (formType == "add") {
            const newData = {...data, date_of_birth: dob}
            payload.data = newData
            payload.setAddMemberModelShow = setAddMemberModelShow
            payload.setMemberIdCardModelShow = setMemberIdCardModelShow
            payload.setIdData = setIdData
            dispatch(addMemberAction(payload))


        } else {
            const newData = {...data, date_of_birth: dob, member_id: getSingleMemberData.id}
            payload.data = newData
            payload.setEditMemberModelShow = setEditMemberModelShow
            payload.setGetSingleMemberData = setGetSingleMemberData
            dispatch(editMemberAction(payload))
        }
    }

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    <Col md={6} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Member Full Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter Member Name"
                                {...register("member_name", {
                                    required: true
                                })}
                            />

                            {/* validation message*/}
                            {errors.member_name && <span className="text-danger">
                        {errors.member_name.type === "required" && "Please Give Member Name"}
                    </span>}
                        </Form.Group>

                    </Col>
                    <Col md={6} sm={12}>
                        {CommonDatePicker.dateField(dob, setDob, "Date of birth")}
                    </Col>
                    <Col md={6} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Guardian Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter Guardian Name"
                                {...register("guardian_name", {
                                    required: true
                                })}
                            />

                            {/* validation message*/}
                            {errors.guardian_name && <span className="text-danger">
                        {errors.guardian_name.type === "required" && "Please Give Guardian Name"}
                    </span>}
                        </Form.Group>

                    </Col>
                    <Col md={6} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Relation with guardian</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter relation with guardian"
                                {...register("relation_with_guardian", {
                                    required: true
                                })}
                            />

                            {/* validation message*/}
                            {errors.relation_with_guardian && <span className="text-danger">
                        {errors.relation_with_guardian.type === "required" && "Please Give Relation"}
                    </span>}
                        </Form.Group>
                    </Col>
                    <Col md={6} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Mobile No.</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter Mobile No."
                                {...register("mobile", {
                                    required: true,
                                    pattern: Regex.numeric()
                                })}
                            />

                            {/* validation message*/}
                            {errors.mobile && <span className="text-danger">
                        {errors.mobile.type === "required" && "Please Give Mobile No."}
                                {errors.mobile.type === "pattern" && "Please Give Valid Mobile No."}

                    </span>}
                        </Form.Group>
                    </Col>
                    <Col md={6} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Agent Id</Form.Label>
                            <Form.Control
                                defaultValue={agentDetail.is_admin != 1 ? agentDetail.id : ""}
                                disabled={!agentDetail.is_admin}
                                type="text"
                                placeholder="Enter agent id"
                                {...register("agent_id", {
                                    required: true,
                                    pattern: Regex.numeric()
                                })}
                            />

                            {/* validation message*/}
                            {errors.agent_id && <span className="text-danger">
                        {errors.agent_id.type === "required" && "Please Give agent id"}
                                {errors.agent_id.type === "pattern" && "Please Give Valid agent id"}

                            </span>}
                        </Form.Group>
                    </Col>
                    <Col md={6} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Start Year</Form.Label>
                            <Form.Control
                                type="text"
                                disabled={formType != "add" ? !agentDetail.is_admin : false}
                                placeholder="Enter Start Year"
                                {...register("start_year", {
                                    required: true,
                                    pattern: Regex.numeric()
                                })}
                            />

                            {/* validation message*/}
                            {errors.start_year && <span className="text-danger">
                        {errors.start_year.type === "required" && "Please Give Start Year"}
                                {errors.start_year.type === "pattern" && "Please Give Valid Start Year"}
                            </span>}
                        </Form.Group>
                    </Col>
                    <Col md={6} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Donation Amount</Form.Label>
                            <Form.Control
                                type="text"
                                disabled={formType != "add" ? !agentDetail.is_admin : false}
                                placeholder="Enter Donation Amount"
                                {...register("yearly_donation_amount", {
                                    required: true,
                                    pattern: Regex.numeric()
                                })}
                            />

                            {/* validation message*/}
                            {errors.yearly_donation_amount && <span className="text-danger">
                        {errors.yearly_donation_amount.type === "required" && "Please Give Amount"}
                                {errors.yearly_donation_amount.type === "pattern" && "Please Give Valid Amount"}

                            </span>}
                        </Form.Group>
                    </Col>


                </Row>

                <Button ref={btnRef} variant="primary" type="submit" className="submit">
                    {formType === "add" ? "Submit" : "Update"}
                </Button>

            </form>
        </>
    );
}

export default MemberForm;