import * as XLSX from "xlsx";
import MemberDueCalc from "./MemberDueCalc";

export const membersExportMethod = (dataForExport) => {
    let membersForExport = []
    dataForExport.map(singleMember => {
        // format member data
        let formattedSingleMember = {
            barcode: singleMember.id,
            member_name: singleMember.member_name,
            date_of_birth: singleMember.date_of_birth,
            relation_with_guardian: singleMember.relation_with_guardian,
            guardian_name: singleMember.guardian_name,
            mobile: singleMember.mobile,
            district: singleMember.user?.collection_area_district,
            police_station: singleMember.user?.collection_area_police_station,
            post_office: singleMember.user?.collection_area_post_office,
            village: singleMember.user?.collection_area_village,
            agent_id: singleMember.agent_id,
            agent_name: singleMember.user?.user_name,
            start_year: singleMember.start_year,
            yearly_donation_amount: singleMember.yearly_donation_amount
        }
        // formate member donation
        singleMember.user_collection_histories?.length != 0 && singleMember.user_collection_histories?.map(collection => {
            //    collection.collection_year == 63 && console.log(singleMember)
            formattedSingleMember["_" + collection.collection_year] = collection.collection_amount
        })
        formattedSingleMember.due_amount = MemberDueCalc.dueCalc(singleMember)
        // data push
        membersForExport.push(formattedSingleMember)
    })
    let wb = XLSX.utils.book_new()
    let ws = XLSX.utils.json_to_sheet(membersForExport)
    XLSX.utils.book_append_sheet(wb, ws, "allMember")
    XLSX.writeFile(wb, "allMember.xlsx")
}