import React, {useContext, useRef} from 'react';
import {Button, Form} from "react-bootstrap";
import {useForm} from "react-hook-form";
import Regex from "../../../Utilities/Regex";
import {AgentContext} from "../AgentComponent";
import {useDispatch} from "react-redux";
import {changePasswordAction} from "../../../Redux/Action/AgentAction";

function ChangePasswordForm(props) {
    // Ref
    const changePasswordRef =useRef()
    // Hook
    const dispatch = useDispatch()

    const {
        setGetSingleAgentData,
        setChangePasswordModelShow,
        getSingleAgentData,
        setAllAgentReload
    }= useContext(AgentContext)
    // hook form
    const {register, handleSubmit, getValues, reset, setValue, formState: {errors}} = useForm();

    const onSubmit = data => {
        const payload ={
            data,
            reset,
            changePasswordRef,
            setAllAgentReload
        }
        const newDate = {...data, user_id: getSingleAgentData.id}
        payload.data =newDate
        payload.setChangePasswordModelShow = setChangePasswordModelShow
        dispatch(changePasswordAction(payload))

    }

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>New Password</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Enter Password"
                        {...register("new_password", {
                            required: true

                        })}
                    />

                    {/* validation message*/}
                    {errors.new_password && <span className="text-danger">
                        {errors.new_password.type === "required" && "Please Give Wallet Amount"}

                    </span>}
                </Form.Group>


                <Button ref={changePasswordRef} variant="primary" type="submit" className="submit">
                    Submit
                </Button>
            </form>
        </>
    );
}

export default ChangePasswordForm;