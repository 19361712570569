import React from 'react';
import {Col, Row} from "react-bootstrap";
import logo from '../../assets/images/logo.png'
import LoginForm from "./SubComponent/LoginForm";
import {ToastContainer} from "react-toastify";

function LoginComponent(props) {
    return (
        <>
            <div className="loginContainer mx-5 mx-md-0">
                <Row className="align-items-center h-100">
                    {/*background column*/}
                    <Col className="loginLeftSide d-none d-md-block" md={8}>
                    </Col>
                    {/*login form*/}
                    <Col className="text-center flex-column d-flex align-items-center justify-content-center" md={4}>
                        <img className="loginLogo" src={logo}/> 
                       <div className="w-100">
                      
                       </div>
                        <LoginForm/>
                    </Col>
                </Row>
                {/*toast container*/}
                <ToastContainer/>
            </div>
        </>
    );
}

export default LoginComponent;